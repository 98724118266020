import * as React from 'react'
import './style.scss'

export const LessonItemPlaceholder = () => (
  <div className="lesson-item-placeholder">
    <div className="lesson-item-placeholder__image" />
    <div className="lesson-item-placeholder__duration" />
    <div className="lesson-item-placeholder__title" />
  </div>
)
