import {
  BehaviorSubject,
  concatMap,
  distinctUntilChanged,
  map,
  Subject,
  takeUntil,
} from 'rxjs'
import {
  ApiCollectionResponse,
  ApiCollectionResponseMeta, PaginationState,
  SearchState,
} from 'core'
import { apiRequest, pathWithParams } from 'core/services/utils'

const filtersWithValue = (filters: SearchState): SearchState => Object
  .entries(filters)
  .filter(([key, value]) => (value != null && value !== ''))
  .reduce((acc, [key, value]) => {
    acc[key] = value
    return acc
  }, {})

export interface ApiCollectionState<T> {
  loading: boolean
  records: T[]
  meta: ApiCollectionResponseMeta
  pagination: PaginationState
  filters: SearchState
}

const defaultMeta: ApiCollectionResponseMeta = { totalCount: null, humanTotalCount: null }

export class DataService<T> {
  readonly state$: BehaviorSubject<ApiCollectionState<T>>

  private readonly apiUrl: string

  private readonly destroyed$ = new Subject<null>()

  private readonly history

  constructor(apiUrl: string, defaultFilters: SearchState, perPage: number, history) {
    this.apiUrl = apiUrl
    this.history = history
    this.loadNextPage = this.loadNextPage.bind(this)
    this.setFilters = this.setFilters.bind(this)

    this.state$ = new BehaviorSubject<ApiCollectionState<T>>({
      loading: true,
      meta: defaultMeta,
      records: [],
      filters: defaultFilters,
      pagination: {
        page: 1,
        perPage,
      },
    })
  }

  init() {
    this.requestOnPagination()
    this.changeHistoryOnFilters()
  }

  setFilters(filters: SearchState) {
    this.setPage(1, { filters: filtersWithValue(filters) })
  }

  loadNextPage() {
    this.setPage(null, { loading: true })
  }

  onDestroy() {
    this.destroyed$.next(null)
  }

  get state(): ApiCollectionState<T> {
    return this.state$.getValue()
  }

  private requestOnPagination() {
    this.state$.pipe(
      map(({ filters, pagination }) => pathWithParams(this.apiUrl, filters, pagination)),
      distinctUntilChanged(),
      concatMap((url) => apiRequest<ApiCollectionResponse<T>>(url)),
      takeUntil(this.destroyed$),
    ).subscribe({
      next: ({ meta, records }) => this.setState({
        meta,
        records: [...this.state.records, ...records],
        loading: false,
      }),
      error: (event) => console.error('error', event),
    })
  }

  private setPage(page?: number, newState: Partial<ApiCollectionState<T>> = {}) {
    const previousPagination = this.state.pagination

    const nextPage = page == null ? previousPagination.page + 1 : page

    this.setState({ ...newState, pagination: { ...previousPagination, page: nextPage } })
  }

  private changeHistoryOnFilters() {
    this.state$.pipe(
      map(({ filters }) => filters),
      distinctUntilChanged((previousFilters, newFilters) => JSON
        .stringify(previousFilters) === JSON.stringify(newFilters)),
    ).subscribe((filters) => {
      this.setState({ loading: true, records: [], meta: defaultMeta })
      this.history.replace(pathWithParams(location.pathname, filters))
    })
  }

  private setState(newState: Partial<ApiCollectionState<T>>) {
    this.state$.next({
      ...this.state,
      ...newState,
    })
  }
}
