import * as React from 'react'
import { Link } from 'react-router-dom'
import './style.scss'

interface LinkProp {
  label: string
  url?: string
}

interface ComponentProps {
  links: LinkProp[]
}

interface BreadcrumbItemProps {
  link: LinkProp,
}

const BreadcrumbItem = ({ link: { url, label } }: BreadcrumbItemProps) => {
  if (!url) {
    return <span className="breadcrumbs__item">{label}</span>
  }

  return <Link className="breadcrumbs__item breadcrumbs__item--gray" key={label} to={url}>{label}</Link>
}

export const Breadcrumbs = ({ links }: ComponentProps) => (
  <p className="breadcrumbs">
    { links.map((link, index) => (
      <React.Fragment key={link.label}>
        <BreadcrumbItem link={link} />
        { (index < links.length - 1) ? (
          <span className="breadcrumbs__item breadcrumbs__item--gray"> / </span>
        ) : null }
      </React.Fragment>
    ))}
  </p>
)
