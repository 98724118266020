import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'
import { appDataSlice } from 'core/features'
import { modalWindowSlice } from './stores/ModalWindow.store'

export const store = configureStore({
  reducer: {
    modalWindow: modalWindowSlice.reducer,
    appData: appDataSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
ReturnType,
RootState,
unknown,
Action<string>
>
