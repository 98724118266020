import * as React from 'react'
import classNames from 'classnames'
import { Button, IconButton } from 'components'
import './style.scss'
import { useTranslation } from 'react-i18next'

interface ComponentProps {
  onChange: (string) => void
  active?: boolean
}

export const TextSearchComponent = (props: ComponentProps) => {
  const { onChange, active } = props

  const [searchActive, setSearchActive] = React.useState<boolean>(active)
  const [value, setValue] = React.useState<string>('')
  const { t } = useTranslation()

  const resetSearch = React.useCallback(() => {
    setValue('')
    onChange('')
  }, [onChange])

  const iconClick = () => {
    setSearchActive(!searchActive)

    if (searchActive && value !== '') {
      resetSearch()
    }
  }

  const listener = React.useCallback(
    (event: KeyboardEvent) => {
      if (event.code === 'Escape' && searchActive) {
        resetSearch()
        setSearchActive(false)
      }
    },
    [resetSearch, searchActive],
  )

  React.useEffect(() => {
    document.addEventListener('keydown', listener)
    return () => document.removeEventListener('keydown', listener)
  })

  return (
    <div className="text-search-component">
      {!active && (
        <IconButton
          icon="search"
          className={classNames('text-search-component__search-button', {
            'text-search-component__search-button-active': searchActive,
          })}
          onClick={iconClick}
        />
      )}
      {searchActive && (
        <form
          onSubmit={(e) => {
            e.preventDefault()
            onChange(value)
          }}
          className="text-search-component__input-container"
        >
          <input
            placeholder={t('general.search.placeholder')}
            onChange={(event) => setValue(event.target.value)}
            className="text-search-component__input"
          />
          <Button type="submit" mode="contained" className="text-search-component__button">
            {t('general.search.button')}
          </Button>
        </form>
      )}
    </div>
  )
}

TextSearchComponent.defaultProps = {
  active: false,
}
