import * as React from 'react'
import { ChipsComponent } from 'components'
import { useAppSelector } from 'core'
import { ChipItem } from 'components/chips/chip-item/ChipItem'
import { selectTags } from 'core/features/appDataSlice'
import './style.scss'

interface ComponentProps {
  selectedTag: string
  onChange: (tagSlug: string) => void
}

export const TagFilterComponent = (props: ComponentProps) => {
  const {
    selectedTag, onChange,
  } = props

  const tags = useAppSelector(selectTags)
  const [tagsToShow, setTagsToShow] = React.useState<number>(tags.length < 5 ? tags.length : 5)
  const availableTags = tags.length - tagsToShow

  const chips = [
    { id: null, name: 'Все' },
    ...tags.slice(0, tagsToShow).map(({ slug, name }) => ({ id: slug, name })),
  ]

  return (
    <>
      <ChipsComponent
        withoutWrapper
        chipClassName="tag-filter-component__tag"
        chips={chips}
        onClick={onChange}
        selectedId={selectedTag}
      />

      { tagsToShow < tags.length ? (
        <ChipItem
          className="tag-filter-component__tag tag-filter-component__more-button"
          onClick={() => {
            setTagsToShow(availableTags < 30 ? tagsToShow + availableTags : tagsToShow + 30)
          }}
        >
          Больше категорий
        </ChipItem>
      ) : null }
    </>
  )
}
