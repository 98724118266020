import * as React from 'react'
import { SponsorRecord } from 'core'
import './style.scss'

interface ComponentProps {
  title: string
  sponsors: SponsorRecord[]
  sponsorClassName?: string
}

export const SponsorsSection = (props: ComponentProps) => {
  const { title, sponsors, sponsorClassName } = props

  if (sponsors.length === 0) {
    return null
  }

  return (
    <section className="sponsors-section">
      <h3 className="sponsors-section__heading">
        {title}
      </h3>

      <div className="sponsors-section__sponsors-block">
        { sponsors.map((sponsor) => (
          <a
            className={sponsorClassName}
            key={sponsor.id}
            href={sponsor.url}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={sponsor.logo.thumb.url}
              className="sponsors-section__sponsor-image"
              alt="sponsor"
            />
          </a>
        )) }
      </div>
    </section>
  )
}

SponsorsSection.defaultProps = {
  sponsorClassName: null,
} as ComponentProps
