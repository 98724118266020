export type IDate = {
  start: string
  end: string
}

export const reformatDates = (
  dates: IDate,
  locale: string,
): {
  original: IDate
  start: { day: string; month: string }
  end: { day: string; month: string }
  isOneDay: boolean
} => {
  const startDate: Date = new Date(dates.start)
  const endDate: Date = new Date(dates.end)

  const defaultLocale = 'en-EN'
  const loc = locale || defaultLocale

  const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: 'short' }

  const startFormatted: string = startDate.toLocaleDateString(loc, options)
  const endFormatted: string = endDate.toLocaleDateString(loc, options)

  const [startDay, startMonth] = startFormatted.split(' ')
  const [endDay, endMonth] = endFormatted.split(' ')

  const isOneDay: boolean = startDate.toDateString() === endDate.toDateString()

  if (loc !== 'ru-RU') {
    // If locale not Russian need revert days and months
    return {
      original: dates,
      start: { day: startMonth, month: startDay },
      end: { day: endMonth, month: endDay },
      isOneDay,
    }
  }

  return {
    original: dates,
    start: { day: startDay, month: startMonth },
    end: { day: endDay, month: endMonth },
    isOneDay,
  }
}
