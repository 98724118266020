import * as React from 'react'
import './style.scss'

export const ContentAuthorPlaceholder = () => (
  <div className="content-author-placeholder">
    <div className="content-author-placeholder__avatar" />
    <div className="content-author-placeholder__text-container">
      <div className="content-author-placeholder__name" />
      <div className="content-author-placeholder__position" />
    </div>
  </div>
)
