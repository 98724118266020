import * as React from 'react'
import classNames from 'classnames'
import './style.scss'

interface ComponentProps {
  centered?: boolean
}

export const PhotoItemPlaceholder = ({ centered }: ComponentProps) => (
  <div className={classNames('photo-item-placeholder', { 'photo-item-placeholder--centered': centered })}>
    <div />
  </div>
)

PhotoItemPlaceholder.defaultProps = {
  centered: false,
} as ComponentProps
