import * as React from 'react'
import { EventPanelItem, SearchableComponent, Content, BackgroundEllipse } from 'components'
import { DateFilters, eventsApiUrl, EventRecord } from 'core'
import { TFunction } from 'i18next'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { useEventsInRow } from 'core/utils'
import { hasNextPage, useApiCollection } from 'core/hooks/useApiCollection'
import './style.scss'
import { useTranslation } from 'react-i18next'

const getTimeTags = (t: TFunction) => {
  const timeTags: DateFilters[] = [
    {
      name: t('pages.events.filters.all'),
      id: 'all',
    },
    {
      name: t('pages.events.filters.past'),
      id: 'past',
    },
    {
      name: t('pages.events.filters.upcoming'),
      id: 'upcoming',
    },
  ]

  return timeTags
}
export const EventsPage = () => {
  const eventsInRow = useEventsInRow()
  const { t } = useTranslation()

  const { state, onNextPage, onFilterChange } = useApiCollection<EventRecord>(
    eventsApiUrl,
    { state: 'all' },
    eventsInRow * 2,
  )
  const { records, meta, loading, filters } = state

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: hasNextPage(state),
    onLoadMore: onNextPage,
    disabled: false,
    rootMargin: '0px 0px 2% 0px',
    delayInMs: 500,
  })

  return (
    <Content first className="events-page">
      <SearchableComponent
        loading={loading}
        responseMeta={meta}
        heading={t('pages.events.search.heading')}
        itemType="event"
        collection={records}
        renderItem={(event) => (
          <EventPanelItem sentryRef={sentryRef} key={event.id} event={event} />
        )}
        searchTypes={['state', 'tag', 'text']}
        searchState={filters}
        stateOptions={getTimeTags(t)}
        onFilterChange={onFilterChange}
        placeholderRows={2}
      />

      <BackgroundEllipse color="blue" className="events-page__ellipse" />
    </Content>
  )
}
