import * as React from "react";

export const useHeadersObserver = (setActiveId, article) => {
  const articlesElementsRef = React.useRef({});

  React.useEffect(() => {
    const callback = (headings) => {
      articlesElementsRef.current = headings.reduce((map, articlesElement) => {
        map[articlesElement.target.id] = articlesElement;
        return map;
      }, articlesElementsRef.current);

      const visibleHeadings = [];

      Object.keys(articlesElementsRef.current).forEach((key) => {
        const headingElement = articlesElementsRef.current[key];
        if (headingElement.isIntersecting) visibleHeadings.push(headingElement);
      });

      const getIndexFromId = (id) =>
        articlesElements.findIndex((heading) => heading.id === id);

      if (visibleHeadings.length === 1) {
        setActiveId(visibleHeadings[0].target.id);
      } else if (visibleHeadings.length > 1) {
        const sortVisibleHeadings = visibleHeadings.sort(
          // @ts-ignore
          (a, b) => getIndexFromId(a.target.id) > getIndexFromId(b.target.id)
        );
        setActiveId(sortVisibleHeadings[0].target.id);
      }
    };

    const observer = new IntersectionObserver(callback, {
      rootMargin: "-10px 0px -40% 0px",
    });

    const articlesElements = Array.from(document.querySelectorAll("h1, h2"));

    articlesElements.forEach((element) => observer.observe(element));

    return () => observer.disconnect();
  }, [setActiveId, article]);
};
