import * as React from 'react'
import classNames from 'classnames'
import { useAppDispatch } from 'redux/hooks'
import { Link, useLocation } from 'react-router-dom'
import { LessonRecord } from 'core'
import { MODAL_OPEN } from 'redux/actions/ModalWindow.actions'
import { DisabledItemModal } from 'modals'
import './style.scss'
import { useTranslation } from 'react-i18next'
import { useLangNavigate } from 'core/hooks/useLangNavigate'

export interface CourseNavigationProps {
  lessons: LessonRecord[]
  visible: boolean
  toggle: () => void
  courseSlug: string
}

export const CourseNavigation = (props: CourseNavigationProps) => {
  const location = useLocation()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { root } = useLangNavigate()

  const { lessons, visible, toggle, courseSlug } = props

  const lessonKey = t('pages.course.lesson.key')
  const showModal = (title) => {
    dispatch(
      MODAL_OPEN({
        active: true,
        children: <DisabledItemModal itemType="Урок" title={title} />,
      }),
    )
    toggle()
  }

  return (
    <div
      className={classNames('course-navigation lesson-page__course-navigation', {
        'is-visible': visible,
      })}
    >
      <div className="course-navigation__lessons">
        {lessons.map((lesson) => {
          if (lesson.active) {
            return (
              <Link
                className={classNames(
                  'course-navigation__lesson',
                  location.pathname.match(lesson.slug) ? 'is-active' : null,
                )}
                to={root.courses.lesson(courseSlug, lesson.slug)}
                key={lesson.id}
                onClick={toggle}
              >
                <div className="course-navigation__lesson-number">
                  <span>{`${lessonKey} ${lesson.number}`}</span>
                </div>
                <div className="course-navigation__lesson-title">
                  <span>{lesson.title}</span>
                </div>
              </Link>
            )
          }

          return (
            <div
              className="course-navigation__lesson course-navigation__lesson--disabled"
              key={lesson.id}
              onClick={() => showModal(lesson.title)}
            >
              <div className="course-navigation__lesson-number">
                <span>{`${lessonKey} ${lesson.number}`}</span>
              </div>
              <div className="course-navigation__lesson-title">
                <span>{lesson.title}</span>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export const CourseNavigationPlaceholder = () => (
  <div className="course-navigation__placeholder">
    <div className="course-navigation__placeholder-item" />
    <div className="course-navigation__placeholder-item" />
    <div className="course-navigation__placeholder-item" />
    <div className="course-navigation__placeholder-item" />
    <div className="course-navigation__placeholder-item" />
    <div className="course-navigation__placeholder-item" />
    <div className="course-navigation__placeholder-item" />
  </div>
)
