import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'

type IFormatLocale = {
  currentLocale: {
    full: string
    short: string
  }
  currentLanguage: string
  isRussianLang: boolean
  changeLangHandler: () => void
  detectLangHandler: () => void
}

export const useLang = (): IFormatLocale => {
  const { i18n } = useTranslation()
  const { pathname } = useLocation()
  const history = useHistory()
  const locale = i18n.language

  const isRussianLang = locale === 'ru'
  const currentLocale = locale
  const currentLocaleShort = locale.slice(0, 2)
  const currentLanguage = isRussianLang ? 'ru-RU' : 'en-EN'

  const changeLangHandler = () => {
    if (locale === 'ru') {
      return history.push(pathname.replace(/^\/ru/, ''))
    }

    if (locale === 'en') {
      return history.push(`/ru${pathname}`)
    }

    if (locale !== 'en' && locale !== 'ru') return i18n.changeLanguage('en')
    return history.push(`/${pathname}`)
  }

  const detectLangHandler = () => {
    if (pathname.startsWith('/ru')) {
      if (pathname === '/ru' || pathname.startsWith('/ru/')) {
        i18n.changeLanguage('ru').then(() => console.log('lang changed to ru'))
      }
    } else {
      i18n.changeLanguage('en').then(() => console.log('lang changed to en'))
    }

    return { langCode: pathname }
  }

  return {
    currentLocale: { short: currentLocaleShort, full: currentLocale },
    isRussianLang,
    currentLanguage,
    changeLangHandler,
    detectLangHandler,
  }
}
