import * as React from 'react'
import { ArticleItem, ShowMoreLink } from 'components'
import { PostRecord } from 'core'
import { useArticlesInRow } from 'core/utils'
import './style.scss'
import { useTranslation } from 'react-i18next'

interface ComponentProps {
  title: string
  articles: PostRecord[]
  showMoreURL?: string
}

export const ArticlesSection = (props: ComponentProps) => {
  const { title, articles, showMoreURL } = props
  const articlesInRow = useArticlesInRow()
  const { t } = useTranslation()

  if (articles.length === 0) {
    return null
  }

  return (
    <section className="articles-section">
      <h3 className="event-item-page__heading">{title}</h3>

      <div
        className={
          articles.length < articlesInRow
            ? 'collection-centered__article-container'
            : 'collection__article-container'
        }
      >
        {articles.map((article) => (
          <ArticleItem
            centered={articles.length < articlesInRow}
            key={article.id}
            article={article}
          />
        ))}
      </div>

      {showMoreURL ? (
        <ShowMoreLink url={showMoreURL} style={{ margin: '0 auto' }}>
          {t('general.ArticlesSection.more')}
        </ShowMoreLink>
      ) : null}
    </section>
  )
}

ArticlesSection.defaultProps = {
  showMoreURL: null,
}
