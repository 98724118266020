import * as React from 'react'
import { FormControlProps } from './interfaces'
import { File } from './fields/File'
import { Input } from './fields/Input'
import { CheckboxGroup } from './fields/CheckboxGroup'
import { RadioButtons } from './fields/RadioButtons'
import { Textarea } from './fields/Textarea'
import { Select } from './fields/Select'
import './style.scss'

export function FormControl(
  {
    control,
    name,
    type,
    items,
    options,
    setFieldValue,
    searchable,
    onChangeHandler,
    min,
    max,
    ...rest
  }: FormControlProps,
) {
  switch (control) {
    case 'input':
      return (
        <Input
          name={name}
          type={type}
          onChangeHandler={onChangeHandler}
          min={min}
          max={max}
          {...rest}
        />
      )
    case 'checkbox':
      return <CheckboxGroup name={name} items={items} {...rest} />
    case 'radio':
      return <RadioButtons tabIndex={0} name={name} options={options} {...rest} />
    case 'textarea':
      return <Textarea name={name} {...rest} />
    case 'select':
      return (
        <Select
          name={name}
          options={options}
          searchable={searchable}
          setFieldValue={setFieldValue}
          {...rest}
        />
      )
    case 'file':
      return <File name={name} setFieldValue={setFieldValue} {...rest} />
    default:
      return null
  }
}
