import * as React from 'react'
import { Content, UnsafeHTML } from 'components'
import { useApiResource } from 'core/hooks/useApiResource'
import { tagApiUrl, TagFullRecord } from 'core'
import { ArticlesSection, EventsSection, JobsSection, SpeakersSection } from 'pages/shared'
import { pathWithParams } from 'core/services'
import './style.scss'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { useLangNavigate } from 'core/hooks/useLangNavigate'

const namedTitle = (title, tag: TagFullRecord, t: TFunction): string =>
  `${title} ${t('pages.tags.bycat')} «${tag.name}»`

export const TagPage = () => {
  const { resource, loading } = useApiResource<TagFullRecord>(tagApiUrl, { titleKey: 'name' })
  const { t } = useTranslation()
  const { root } = useLangNavigate()

  if (loading) {
    return <h1>{t('general.loading')}</h1>
  }
  let eventsURL
  let articlesURL
  let speakersURL
  let vacanciesURL

  if (resource.related_events.length >= 8) {
    eventsURL = pathWithParams(root.events.main, { tag: resource.slug })
  }

  if (resource.related_posts.length >= 6) {
    articlesURL = pathWithParams(root.blog.main, { tag: resource.slug })
  }

  if (resource.related_speakers.length >= 16) {
    speakersURL = pathWithParams(root.speakers.main, { tag: resource.slug })
  }

  if (resource.related_vacancies.length >= 16) {
    vacanciesURL = pathWithParams(root.jobs.main, { tag: resource.slug })
  }
  return (
    <Content first className="tag-page">
      <h1 className="tag-page__heading">
        {resource.description
          ? `${t('pages.tags.bycat')} ${t('pages.tags.whatis')} ${resource.name}?`
          : resource.name}
      </h1>

      {resource.description ? (
        <UnsafeHTML className="tag-page__description">{resource.description}</UnsafeHTML>
      ) : null}

      <EventsSection
        events={resource.related_events}
        title={namedTitle(t('pages.tags.events.heading'), resource, t)}
        showMoreURL={eventsURL}
      />
      <ArticlesSection
        articles={resource.related_posts}
        title={namedTitle(t('pages.tags.articles.heading'), resource, t)}
        showMoreURL={articlesURL}
      />
      <SpeakersSection
        speakers={resource.related_speakers}
        title={namedTitle(t('pages.tags.speakers.heading'), resource, t)}
        showMoreURL={speakersURL}
      />
      <JobsSection
        jobs={resource.related_vacancies}
        title={namedTitle(t('pages.tags.vacancies.heading'), resource, t)}
        showMoreURL={vacanciesURL}
      />
    </Content>
  )
}
