import * as React from 'react'
import { ReactSVG } from 'react-svg'
import { IconName } from 'core'
import classnames from 'classnames'
import './style.scss'

interface ComponentProps {
  icon: IconName
  className?: string
}

export const Icon = (props: ComponentProps) => {
  const { icon, className } = props

  // eslint-disable-next-line global-require,import/no-dynamic-require
  const iconSrc = require(`/app/assets/icons/${icon}`)

  return (
    <span className={classnames('icon', className)}>
      <ReactSVG src={iconSrc} />
    </span>
  )
}

Icon.defaultProps = {
  className: null,
}
