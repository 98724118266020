import * as React from 'react'
import { Image } from 'components'
import { useAppSelector } from 'core'
import { selectOurTeam } from 'core/features'
import './style.scss'
import { useTranslation } from 'react-i18next'

const figure = require('/app/assets/images/our-team-bg.svg')

export const OurTeam = () => {
  const ourTeam = useAppSelector(selectOurTeam)
  const { t } = useTranslation()

  return (
    <section className="our-team">
      <img src={figure} alt="figure" className="our-team__figure" />
      <h2 className="our-team__heading">{t('pages.home.team.heading')}</h2>
      {/* <p className="our-team__description"> */}
      {/*  А также сторонники тоталитаризма в науке неоднозначны */}
      {/*  и будут функционально разнесены на независимые элементы. */}
      {/*  Являясь всего лишь частью общей картины анафеме. */}
      {/* </p> */}
      <div className="our-team__members-block">
        {ourTeam.map((member) => (
          <div key={member.id} className="our-team__member">
            <Image
              src={member.avatar.large.url}
              className="our-team__member-image"
              placeholderClassName="our-team__member-image-placeholder"
              alt="member"
            />
            <h3 className="our-team__member-name">{member.full_name}</h3>
            <p className="our-team__member-activity">{member.role}</p>
          </div>
        ))}
      </div>
    </section>
  )
}
