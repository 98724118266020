import * as React from 'react'
import { LessonPage } from 'pages/lesson-page/LessonPage'
import { Content, LessonItem, LessonItemPlaceholder } from 'components'
import { useApiResource } from 'core/hooks/useApiResource'
import { useCoursesInRow } from 'core/utils'
import { CourseFullRecord, courseApiUrl } from 'core'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { useLangNavigate } from 'core/hooks/useLangNavigate'

export const CoursePage = () => {
  const { path } = useRouteMatch()
  const { resource, loading } = useApiResource<CourseFullRecord>(courseApiUrl)
  const lessonsRenderQuantity = useCoursesInRow() * 2
  const { root } = useLangNavigate()

  return (
    <Switch>
      <Route path={path} exact>
        <div className="course-page__wrapper">
          <Content first className="course-page">
            {!loading ? (
              <h1 className="course-page__title">{resource.title}</h1>
            ) : (
              <div className="course-page__title-placeholder" />
            )}
            <div className="course-page__container">
              {!loading
                ? resource.lessons.map((lesson, index) => (
                    <LessonItem
                      url={root.courses.lesson(resource.slug, lesson.slug)}
                      lesson={lesson}
                      key={index}
                    />
                  ))
                : new Array(lessonsRenderQuantity)
                    .fill(null)
                    .map((value, index) => <LessonItemPlaceholder key={index} />)}
            </div>
          </Content>
        </div>
      </Route>
      <Route path={`${path}/:slug`}>
        <LessonPage />
      </Route>
    </Switch>
  )
}
