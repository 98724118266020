import * as React from 'react'
import { Content, UnsafeHTML } from 'components'
import './style.scss'
import { useAppSelector } from 'core'

export const AboutUsPage = () => {
  const staticData = useAppSelector((state) => state.appData.static)
  const { aboutUs: { title, description } } = staticData

  return (
    <Content first className="about-us-page__content">
      <h2 className="about-us-page__heading">{title}</h2>

      <UnsafeHTML qlEditor={false} className="about-us-page__text-block">{description}</UnsafeHTML>
    </Content>
  )
}
