import * as React from 'react'
import {
  Field, ErrorMessage, useField, useFormikContext,
} from 'formik'
import classNames from 'classnames'
import { FieldProps } from '../interfaces'

export const Input: React.FC<FieldProps> = ({
  name,
  type,
  labelName,
  labelText,
  wrapperClassName,
  fieldClassName,
  required,
  disabled,
  hideErrorMessage,
  placeholder,
  onChangeHandler,
  min,
  max,
} :FieldProps) => {
  const formik = useFormikContext()
  const [field, meta] = useField(name)
  const isError = meta.error && meta.touched
  const isValid = !meta.error && meta.touched

  return (
    <label className={classNames('form-control', wrapperClassName, { required })}>
      {labelName && <h2>{labelName}</h2>}
      {labelText && <p>{labelText}</p>}
      <Field
        name={name}
        type={type}
        className={classNames('form-control__field',
          fieldClassName,
          { 'no-valid': isError },
          { valid: isValid })}
        placeholder={placeholder}
        disabled={disabled}
        onChange={onChangeHandler
          ? (e) => onChangeHandler(e)
          : (e) => formik.setFieldValue(name, e.target.value)}
        min={min}
        max={max}
      />
      {!hideErrorMessage && (
      <ErrorMessage
        name={name}
        className="form-control__error"
        component="div"
      />
      )}
    </label>
  )
}

Input.defaultProps = {
  type: 'text',
  labelName: '',
  labelText: '',
  wrapperClassName: '',
  fieldClassName: '',
  required: false,
  disabled: false,
  hideErrorMessage: false,
  placeholder: null,
  onChangeHandler: false,
} as unknown as FieldProps
