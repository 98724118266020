import * as React from 'react'
import { GlobalEventRecord } from 'core'

import { GlobalItem } from 'components/global-events/item/global-item'
import styles from './global-group.module.scss'

type Props = {
  title: string
  data: GlobalEventRecord[]
}

export const GlobalEventGroup = (props: Props) => {
  const { title, data } = props
  return (
    <div className={`content__wrapper ${styles.width}`}>
      <div className={styles.wrapper}>
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.items}>
          {Array.isArray(data) &&
            data.map((globalEvent) => <GlobalItem key={globalEvent.id} {...globalEvent} />)}
        </div>
      </div>
    </div>
  )
}
