import * as React from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import { Icon } from 'components'
import './style.scss'

interface ComponentProps {
  url: string
  children: string
  className?: string
  style?: React.CSSProperties
  blank?: boolean
}

export const ShowMoreLink = (props: ComponentProps) => {
  const {
    url,
    children,
    className,
    style,
    blank,
  } = props

  if (blank) {
    return (
      <a
        href={url}
        style={style}
        target="_blank"
        rel="noreferrer"
        className={classnames('show-more-link', className)}
      >
        {children}
      </a>
    )
  }

  return (
    <Link
      to={url}
      style={style}
      rel={blank ? 'noreferrer' : undefined}
      className={classnames('show-more-link', className)}
    >
      {children}
      <Icon icon="arrow-right" className="show-more-link__icon" />
    </Link>
  )
}

ShowMoreLink.defaultProps = {
  className: null,
  style: null,
  blank: false,
}
