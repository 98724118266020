import * as React from 'react'
import { useApiResource } from 'core/hooks/useApiResource'
import { articleApiUrl, PostFullRecord } from 'core'
import { useArticlesInRow } from 'core/utils'
import classNames from 'classnames'
import {
  ArticleCover,
  ArticleItem,
  ArticleItemPlaceholder,
  BackgroundEllipse,
  Breadcrumbs,
  Content,
  ContentAuthor,
  ContentAuthorPlaceholder,
  LightboxImages,
  TopBar,
  UnsafeHTML,
} from 'components'
import { EventsSection } from 'pages/shared'
import TableOfContent from 'pages/article-page/table-of-content/TableOfContent'
import { useEffect } from 'react'
import './style.scss'
import { useTranslation } from 'react-i18next'
import { useLangNavigate } from 'core/hooks/useLangNavigate'

export const ArticlePage = () => {
  const { root } = useLangNavigate()

  const { resource, loading } = useApiResource<PostFullRecord>(articleApiUrl)
  const [tableOfContentsVisible, setTableOfContentsVisible] = React.useState<boolean>(false)

  const [isBodyHeight, setIsBodyHeight] = React.useState<number>(0)

  const { t } = useTranslation()
  const calcContentHeight = () => {
    const height = document.body.querySelector('.article').scrollHeight
    setIsBodyHeight(height)
  }
  useEffect(() => {
    // todo fix, this temporary
    if (!loading) {
      setTimeout(calcContentHeight, 500)
      if (!resource.show_table_of_content) {
        document.body.querySelector('.header').classList.remove('header--no-fixed')
      }
    }
  }, [loading])

  const [isMobile, setIsMobile] = React.useState<boolean>(true)
  useEffect(() => {
    setIsMobile(window.innerWidth <= 1185)
  }, [])

  const toggleTableOfContents = () => {
    if (isMobile) setTableOfContentsVisible(!tableOfContentsVisible)
  }

  const articlesInRow = useArticlesInRow()
  const amountOfArticles = loading ? articlesInRow : resource.related_posts.length

  let resourceNew = ''
  if (!loading) {
    if (resource.show_table_of_content) {
      const newArr = []
      const resourceArr = resource.content.split('</h2>')
      for (let i = 0; i < resourceArr.length; i++) {
        newArr.push(resourceArr[i].replace('<h2>', `<h2 id='title${i}'>`))
      }
      resourceNew = newArr.join('</h2>')
    } else {
      resourceNew = resource.content
    }
  }

  const bodyHeight = {
    height: `${isBodyHeight}px`,
  }

  return (
    <div className="article">
      {!loading && resource.show_table_of_content && (
        <TopBar
          title={resource.title}
          visible={tableOfContentsVisible}
          toggle={toggleTableOfContents}
          backUrl={root.blog.main}
          backText={t('pages.article.topbar.backText')}
          actionText={t('pages.article.topbar.actionText')}
        />
      )}
      <Content first className="article-page" mainWrapperClassName="article-page__wrapper">
        <div className="article-page__main-block">
          <Breadcrumbs
            links={[
              { url: root.blog.main, label: 'Cтатьи' },
              { label: !loading ? resource.title : null },
            ]}
          />
          <h1
            id="title"
            className={classNames('article-page__title', {
              'article-page--loading': loading,
            })}
          >
            {!loading ? resource.title : null}
          </h1>
          {!loading ? (
            <ContentAuthor author={resource.user} type="article" />
          ) : (
            <ContentAuthorPlaceholder />
          )}
          <div className="article-page__time">
            {!loading ? <span>{resource.human_date}</span> : null}
            {!loading ? (
              resource.reading_duration ? (
                <span className="article-page__time-reading">{resource.reading_duration}</span>
              ) : null
            ) : null}
          </div>
          <div className="article-page__content-grid">
            {!loading && (
              <ArticleCover
                video={resource.video}
                image_url={resource.background_image.url}
                title={resource.title}
              />
            )}
            <UnsafeHTML
              className={classNames('article-page__content', {
                'article-page--loading': loading,
              })}
              qlEditor={false}
            >
              {!loading ? resourceNew : null}
            </UnsafeHTML>
            {!loading
              ? resource.show_table_of_content && (
                  <TableOfContent
                    article={resource}
                    mobileVisible={tableOfContentsVisible}
                    toggleTableOfContents={toggleTableOfContents}
                  />
                )
              : null}
          </div>
        </div>
        <div className="article-page__articles-block">
          <h3 className="article-page__article-heading">{t('pages.article.readMore')}</h3>
          <div
            className={
              amountOfArticles < articlesInRow
                ? 'collection-centered__article-container'
                : 'collection__article-container'
            }
          >
            {!loading
              ? resource.related_posts.map((article) => (
                  <ArticleItem
                    key={article.id}
                    centered={resource.related_posts.length < articlesInRow}
                    article={article}
                  />
                ))
              : new Array(articlesInRow)
                  .fill(null)
                  .map((value, index) => <ArticleItemPlaceholder key={index} />)}
          </div>
        </div>
        {!loading ? (
          <EventsSection title={t('general.EventsSection.heading')} events={resource.events} />
        ) : null}
      </Content>
      <div className="article-page__ellipse-wrapper" style={bodyHeight}>
        <BackgroundEllipse
          color="green-1"
          className="article-page__ellipse article-page__ellipse--1"
        />
        <BackgroundEllipse
          color="orange"
          className="article-page__ellipse article-page__ellipse--2"
        />
        <BackgroundEllipse
          color="blue"
          className="article-page__ellipse article-page__ellipse--3"
        />
      </div>
      {!loading && <LightboxImages images={resource.images} />}
    </div>
  )
}
