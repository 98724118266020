import * as React from 'react'
import './style.scss'

export const JobItemPlaceholder = () => (
  <div className="job-item-placeholder">
    <div className="job-item-placeholder__company" />
    <div className="job-item-placeholder__title" />
    <div className="job-item-placeholder__title--sub" />
    <div className="job-item-placeholder__salary" />
    <div className="job-item-placeholder__experience" />
    <div className="job-item-placeholder__stack" />
  </div>
)
