import * as React from 'react'
import Lightbox from 'react-image-lightbox'
import { ContentLink } from 'core'
import './style.scss'

interface ComponentProps {
  images: ContentLink<'lightbox'>[]
}

export const LightboxImages = ({ images }: ComponentProps) => {
  const [isOpened, setIsOpened] = React.useState<boolean>(false)
  const [photoIndex, setPhotoIndex] = React.useState<number>(0)

  const toggleLightbox = (lightbox_url) => {
    images.forEach((image, index) => {
      if (lightbox_url.match(image.lightbox.url)) {
        setPhotoIndex(index)
        setIsOpened(true)
      }
    })
  }

  function targetImages() {
    const imagesContent = document.body.querySelectorAll('[data-lightbox="true"]')

    imagesContent.forEach((image) => (
      image.addEventListener('click', (e) => {
        const lightboxImageUrl = (e.target as HTMLImageElement).src
        toggleLightbox(lightboxImageUrl)
      })
    ))
  }

  React.useEffect(() => {
    targetImages()
  }, [])

  return (
    <>
      { isOpened && (
        <Lightbox
          animationDisabled
          mainSrc={images[photoIndex].url}
          nextSrc={images[(photoIndex + 1) % images.length].url}
          prevSrc={images[(photoIndex + images.length - 1) % images.length].url}
          onCloseRequest={() => setIsOpened(false)}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
        />
      )}
    </>
  )
}
