import * as React from 'react'
import { useApiCollection } from 'core/hooks/useApiCollection'
import { CourseRecord, coursesApiUrl } from 'core'
import { useCoursesInRow } from 'core/utils'
import { Content, CourseItem, CourseItemPlaceholder } from 'components'
import './style.scss'

export const CoursesPage = () => {
  const coursesRenderQuantity = useCoursesInRow() * 4

  const {
    state,
  } = useApiCollection<CourseRecord>(coursesApiUrl, {}, coursesRenderQuantity)
  const {
    records,
    loading,
  } = state

  return (
    <div className="course-page__wrapper">
      <Content
        first
        className="course-page"
      >
        <h1 className="course-page__title">Погружение в Web3</h1>
        <div className="course-page__container">
          {!loading
            ? records.map((course, index) => (
              <CourseItem key={index} course={course} />
            ))
            : new Array(coursesRenderQuantity).fill(null).map((value, index) => (
              <CourseItemPlaceholder key={index} />
            ))}
        </div>
      </Content>
    </div>
  )
}
