import { createSlice } from '@reduxjs/toolkit'
import { ModalWindowProps } from 'components'
import { ACTIONS } from '../enum'
import { closeModalWindow, openModalWindow } from '../reducers/ModalWindow.reducers'
import { Status } from '../types'
import { ModalWindowState } from '../types/ModalWindow.types'

const initialState = {
  status: Status.LOADING,
  payload: null,
}

export const modalWindowSlice = createSlice({
  name: 'ModalWindows',
  initialState,
  reducers: {
    [ACTIONS.MODAL_OPEN]: (state: ModalWindowState, action: { payload: ModalWindowProps }) =>
      openModalWindow(state, action.payload),
    [ACTIONS.MODAL_CLOSE]: (state: ModalWindowState) => closeModalWindow(state),
  },
})
