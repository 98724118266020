import * as React from 'react'

import cx from 'classnames'
import { ReactNode, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { Status } from 'redux/types'
import { MODAL_CLOSE } from 'redux/actions/ModalWindow.actions'
import { IconButton } from 'components/icon-button/IconButton'
import css from './ModalWindow.module.scss'

export type ModalWindowProps = {
  active: boolean
  children: ReactNode
}

export const ModalWindow = () => {
  const modalStore = useAppSelector((state) => state.modalWindow)
  const dispatch = useAppDispatch()
  const [payload, setPayload] = useState<ModalWindowProps>(null)

  useEffect(() => {
    if (modalStore && modalStore.status === Status.SUCCESS) {
      setPayload(modalStore.payload)
      document.body.style.overflow = modalStore.payload.active ? 'hidden' : null
    }
  }, [modalStore])

  const onClose = () => {
    dispatch(MODAL_CLOSE())
  }

  return (
    <>
      {payload && payload.active && payload.children && (
        <div className={cx(css.modal_container)}>
          <div onClick={onClose} className={cx(css.modal_container__cover)} />
          <div className={cx(css.modal_container__content)}>
            <IconButton className={cx(css.modal_container__button_close)} icon="close" onClick={onClose} />
            <div className={cx(css.modal_container__children)}>{payload.children}</div>
          </div>
        </div>
      )}
    </>
  )
}
