import * as React from 'react'
import {
  Field, ErrorMessage, useField,
} from 'formik'
import classNames from 'classnames'
import { FieldProps } from '../interfaces'

export const Textarea: React.FC<FieldProps> = ({
  name,
  labelName,
  labelText,
  wrapperClassName,
  fieldClassName,
  required,
  placeholder,
}: FieldProps) => {
  const [field, meta] = useField(name)
  const isError = meta.error && meta.touched
  const isValid = !meta.error && meta.touched
  return (
    <label className={classNames('form-control', wrapperClassName, { required })}>
      {labelName && <h2>{labelName}</h2>}
      {labelText && <p>{labelText}</p>}
      <Field
        as="textarea"
        name={name}
        className={classNames('form-control__textarea',
          fieldClassName,
          { 'no-valid': isError },
          { valid: isValid })}
        placeholder={placeholder}
      />
      <ErrorMessage
        name={name}
        className="form-control__error"
        component="div"
      />
    </label>
  )
}
