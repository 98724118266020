import * as React from 'react'
import { ErrorMessage, Field } from 'formik'
import classNames from 'classnames'
import ReactSelect from 'react-select'
import { FormControlOptions, OptionItem } from '../interfaces'

const customStyles = {
  control: (styles, state) => ({
    ...styles,
    outline: 'none',
    backgroundColor: 'white',
    minHeight: '48px',
    height: '100%',
    borderRadius: '6px',
    border: (state.isFocused || state.isSelected) ? '2px solid #43FFDD' : 'none',
    '&:hover': {
      borderColor: '#43FFDD',
      boxShadow: '0 0 0 1px #43FFDD',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: '1px dotted black;',
    backgroundColor: state.isSelected ? '#43FFDD' : 'white',
    color: 'black',
    padding: '10px 20px',
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1
    const transition = 'opacity 300ms'

    return { ...provided, opacity, transition }
  },
}

export const Select: React.FC<FormControlOptions> = (
  {
    name,
    labelName,
    labelText,
    wrapperClassName,
    fieldClassName,
    required,
    options,
    placeholder,
    searchable,
    isMulti,
    setFieldValue,
    other,
    showOtherField,
  }: FormControlOptions,
) =>
  (
    <label className={classNames('form-control', wrapperClassName, { required })}>
      {labelName && <h2>{labelName}</h2>}
      {labelText && <p>{labelText}</p>}
      {searchable ? (
        <ReactSelect
          id={name}
          name={name}
          options={options}
          className={fieldClassName}
          styles={customStyles}
          placeholder={placeholder}
          onChange={(option) => {
            setFieldValue(
              name,
              isMulti
                ? (option as OptionItem[]).map((item: OptionItem) => item.value)
                : (option as OptionItem).value,
            )
          }}
          isMulti={isMulti}
          maxMenuHeight={300}
        />
      )
        : (
          <Field
            id={name}
            name={name}
            as="select"
            options={options}
            className={classNames('form-control__select', fieldClassName)}
          >
            {options.map((option, index) => (
              <option key={index} id={`${index}-${option.value}`} value={option.value}>
                {option.label}
              </option>
            ))}
          </Field>
        )}
      {(other != null)
        && (
          <div className="form-control-checkboxes__item form-control-checkboxes__item--other form-control-checkboxes__item--other-select" key={options.length + 1}>
            <div
              tabIndex={0}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  (event.target as HTMLInputElement).click()
                }
              }}
              onClick={() => showOtherField(!other)}
              className={classNames('chip-item', { active: other })}
            >
              other
            </div>
          </div>
        )}
      <ErrorMessage
        name={name}
        className="form-control__error"
        component="div"
      />
    </label>
  )

Select.defaultProps = {
  options: [],
  searchable: false,
}
