import * as React from 'react'
import './style.scss'

export const SpeakerItemPlaceholder = () => (
  <div className="speaker-item-placeholder">
    <div className="speaker-item-placeholder__image" />

    <div className="speaker-item-placeholder__name" />

    <div className="speaker-item-placeholder__company" />
  </div>
)
