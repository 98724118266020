import { GlobalEventRecord } from 'core'

const parseDate = (humanDate: string) => {
  const [, day, month, year] = humanDate.split(/\s+/)
  return new Date(`${year} ${month} ${day}`)
}

export const groupByMonth = (data: GlobalEventRecord[]) => {
  const grouped = {}
  data.forEach((record) => {
    const date = parseDate(record.human_date)
    const monthYear = `${date.getMonth()}-${date.getFullYear()}`
    if (!grouped[monthYear]) {
      grouped[monthYear] = []
    }
    grouped[monthYear].push(record)
  })
  return grouped
}

interface ICity {
  id: number
  name: string
}

// Function to get all unique cities from the data
export const getAllCities = (data: GlobalEventRecord[]): ICity[] => {
  const citiesMap: { [key: number]: string } = {}
  const cities: ICity[] = []

  data.forEach((record) => {
    const endDate = new Date(record.date.end)

    const currentDate = new Date() // Получаем текущую дату
    if (endDate < currentDate) return

    if (record.city && !citiesMap[record.city.id]) {
      citiesMap[record.city.id] = record.city.name
      cities.push({
        id: record.city.id,
        name: record.city.name,
      })
    }
  })

  return cities
}

export type IFilters = {
  id: number
  name: string
}

export const filterEventsByCityIds = (events: GlobalEventRecord[], cityIds) => {
  if (!cityIds || !cityIds.length) {
    return { records: events }
  }

  const filteredRecords = events.filter((event: GlobalEventRecord) =>
    cityIds.includes(event.city?.id),
  )
  return { records: filteredRecords }
}

export const separateByMonth = (data: GlobalEventRecord[], locale: string) => {
  const defaultLocale = 'en-EN'
  const loc = locale !== '' ? locale : defaultLocale

  const separatedData: { label: string; records: GlobalEventRecord[] }[] = []

  data.forEach((record) => {
    const startDate = new Date(record.date.start)
    const endDate = new Date(record.date.end)

    const currentDate = new Date() // Получаем текущую дату

    if (endDate < currentDate) {
      return
    }

    const month = startDate.toLocaleString(loc, { month: 'long' })
    const year = startDate.getFullYear()

    const monthYearLabel = `${month} ${year}`

    const existingMonthIndex = separatedData.findIndex((item) => item.label === monthYearLabel)

    if (existingMonthIndex === -1) {
      separatedData.push({
        label: monthYearLabel,
        records: [record],
      })
    } else {
      separatedData[existingMonthIndex].records.push(record)
    }
  })

  return separatedData
}
