import { useHistory, useParams } from 'react-router-dom'
import * as React from 'react'
import { apiRequest, handleError } from 'core/services'

interface HookProps<T> {
  resource: T
  loading: boolean
}

interface HookOptions {
  titleKey?: string
}

export const useApiResource = <T>(
  apiUrlGetter: (slug: string) => string,
  { titleKey }: HookOptions = {},
): HookProps<T> => {
  const { slug } = useParams<{ slug: string }>()
  const [loading, setLoading] = React.useState<boolean>(true)
  const [resource, setResource] = React.useState<T>(null)
  const history = useHistory()

  React.useEffect(() => {
    apiRequest(apiUrlGetter(slug)).subscribe({
      next: (newResource) => {
        setResource(newResource as any)
        setLoading(false)
      },
      error: (error) => {
        handleError(error, history)
      },
    })
  }, [apiUrlGetter, history, slug, titleKey])

  return {
    loading,
    resource,
  }
}
