import { ProductRecord } from 'core'

const productImage = require('/app/assets/images/product-temp-image.png')

export const products = [
  {
    id: 1,
    image: { thumb: { url: productImage }},
    title: 'Футболка',
    description: 'Описание продукта Внезапно, действия представителей оппозиции будут рассмотрены исключительно в разрезе маркетинговых и финансовых предпосылок.',
    price: 240,
  },
  {
    id: 2,
    image: { thumb: { url: productImage }},
    title: 'Футболка',
    description: 'Описание продукта Внезапно, действия представителей оппозиции будут рассмотрены исключительно в разрезе маркетинговых и финансовых предпосылок.',
    price: 240,
  },
  {
    id: 3,
    image: { thumb: { url: productImage }},
    title: 'Футболка',
    description: 'Описание продукта Внезапно, действия представителей оппозиции будут рассмотрены исключительно в разрезе маркетинговых и финансовых предпосылок.',
    price: 240,
  },
  {
    id: 4,
    image: { thumb: { url: productImage }},
    title: 'Футболка',
    description: 'Описание продукта Внезапно, действия представителей оппозиции будут рассмотрены исключительно в разрезе маркетинговых и финансовых предпосылок.',
    price: 240,
  },
  {
    id: 5,
    image: { thumb: { url: productImage }},
    title: 'Футболка',
    description: 'Описание продукта Внезапно, действия представителей оппозиции будут рассмотрены исключительно в разрезе маркетинговых и финансовых предпосылок.',
    price: 240,
  },
  {
    id: 6,
    image: { thumb: { url: productImage }},
    title: 'Футболка',
    description: 'Описание продукта Внезапно, действия представителей оппозиции будут рассмотрены исключительно в разрезе маркетинговых и финансовых предпосылок.',
    price: 240,
  },
] as ProductRecord[]
