import * as React from 'react'
import classNames from 'classnames'
import { Button, Icon } from 'components'
import './style.scss'

export interface ComponentProps {
  title: string
  visible: boolean
  toggle: () => void
  backUrl: string
  backText: string
  actionText?: string
}

export const TopBar = (props: ComponentProps) => {
  const {
    title, toggle, visible, backUrl, backText, actionText,
  } = props

  const [isActive, setIsActive] = React.useState<boolean>(false)
  const [scrollWidth, setScrollWidth] = React.useState<number>(0)

  window.addEventListener('scroll', () => {
    const scrollTop = window.scrollY
    const docHeight = document.body.offsetHeight
    const winHeight = window.innerHeight
    const scrollPercent = scrollTop / (docHeight - winHeight)
    const scrollPercentRounded = Math.round(scrollPercent * 100)
    setScrollWidth(scrollPercentRounded)
    if (scrollTop >= 300) {
      setIsActive(true)
    } else {
      setIsActive(false)
      if (visible) {
        toggle()
      }
    }
  })

  const scrollStyles = {
    width: `${scrollWidth}%`,
  }

  return (
    <div className={classNames('top-bar', { 'is-active': isActive })}>
      <div className="top-bar__wrapper">
        <div className="top-bar__back">
          <a aria-label={backText} href={backUrl} className="top-bar__back-link">
            <Icon icon="arrow-back" className="top-bar__back-icon" />
          </a>
          <div className="top-bar__title"><span>{title}</span></div>
        </div>
        <Button
          mode="default"
          className={classNames('top-bar__table-of-contents', { 'is-active': visible })}
          onClick={toggle}
        >
          <Icon icon="table-of-contents" className="top-bar__table-of-contents-icon" />
        </Button>
        {actionText && (<span>{actionText}</span>)}
      </div>
      <div id="showScroll" className="scroll-status" style={scrollStyles} />
    </div>
  )
}
