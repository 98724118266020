import * as React from 'react'
import classNames from 'classnames'

interface ComponentProps {
  children: string
  className?: string
  qlEditor?: boolean
}

export const UnsafeHTML = ({ className, children, qlEditor }: ComponentProps) => (
  // todo change dangerouslySetInnerHTML later, because is not secure
  // eslint-disable-next-line react/no-danger
  <div
    dangerouslySetInnerHTML={{ __html: children }}
    className={classNames({ 'ql-editor': qlEditor }, className, 'content-text')}
  />
)

UnsafeHTML.defaultProps = {
  className: null,
  qlEditor: true,
}
