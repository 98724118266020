import * as React from 'react'
import classNames from 'classnames'
import './style.scss'

interface ComponentProps {
  children: string
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  className?: string
  contained?: boolean
}

export const ChipItem = (props: ComponentProps) => {
  const {
    children,
    onClick,
    className,
    contained,
  } = props

  if (onClick) {
    return (
      <button
        className={
          classNames('chip-item', { 'chip-item--contained': contained }, className)
        }
        type="button"
        onClick={onClick}
      >
        {children}
      </button>
    )
  }
  return (
    <div className={
        classNames('chip-item', { 'chip-item--contained': contained }, className)
      }
    >
      {children}
    </div>
  )
}

ChipItem.defaultProps = {
  onClick: null,
  className: null,
  contained: false,
}
