import * as React from 'react'
import classNames from 'classnames'
import './style.scss'

interface ComponentProps {
  centered: boolean
}

export const VideoItemPlaceholder = ({ centered }: ComponentProps) => (
  <div className={classNames('video-item-placeholder', { 'video-item-placeholder--centered': centered })}>
    <div />
  </div>
)
