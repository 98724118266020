import * as React from 'react'
import { ShowMoreLink, SpeakerItem } from 'components'
import { UserRecord } from 'core'
import { useSpeakersInRow } from 'core/utils'
import './style.scss'
import { useTranslation } from 'react-i18next'

interface ComponentProps {
  title: string
  speakers: UserRecord[]
  showMoreURL?: string
}

export const SpeakersSection = (props: ComponentProps) => {
  const { title, speakers, showMoreURL } = props
  const speakersInRow = useSpeakersInRow()
  const { t } = useTranslation()

  if (speakers.length === 0) {
    return null
  }

  return (
    <section className="event-item-speakers">
      <h3 className="event-item-page__heading">{title}</h3>

      <div
        className={
          speakers.length < speakersInRow
            ? 'collection-centered__speaker-container'
            : 'collection__speaker-container'
        }
      >
        {speakers.map((speaker) => (
          <SpeakerItem
            centered={speakers.length < speakersInRow}
            key={speaker.id}
            speaker={speaker}
          />
        ))}
      </div>

      {showMoreURL ? (
        <ShowMoreLink
          url={showMoreURL}
          style={{
            margin: '0 auto',
          }}
        >
          {t('general.SpeakersSection.more')}
        </ShowMoreLink>
      ) : null}
    </section>
  )
}

SpeakersSection.defaultProps = {
  showMoreURL: null,
}
