import * as React from 'react'

interface ComponentProps {
  headings: any,
  activeId: number
  toggleTableOfContents: () => void
}

export const ArticlesHeadings = (
  { headings, activeId, toggleTableOfContents } : ComponentProps,
) => (
  <ul>
    {headings.map((heading) => (
      <li
        key={heading.id}
        className={heading.id === activeId ? 'active' : 'unactive'}
      >
        <a
          href={`#${heading.id}`}
          onClick={(e) => {
            e.preventDefault()
            const element = document.querySelector(`#${heading.id}`)
            const headerOffset = -70
            const position = element.getBoundingClientRect().top + window.pageYOffset + headerOffset
            window.scrollTo({ top: position, behavior: 'smooth' })
            toggleTableOfContents()
          }}
        >
          {heading.title}
        </a>
      </li>
    ))}
  </ul>
)
