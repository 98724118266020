import * as React from 'react'
import classNames from 'classnames'
import { Button, Icon } from 'components'
import './style.scss'

export const ScrollTop = () => {
  const [visible, setVisible] = React.useState<boolean>(false)
  const [active, setActive] = React.useState<boolean>(false)
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop
    if (scrolled > 300) {
      setVisible(true)
    } else if (scrolled <= 300) {
      setVisible(false)
      setActive(false)
    }
  }
  const scrollToTop = () => {
    setActive(true)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  React.useEffect(() => {
    window.addEventListener('scroll', toggleVisible)
    return () => window.removeEventListener('scroll', toggleVisible)
  }, [])

  return (
    <Button
      mode="default"
      onClick={scrollToTop}
      className={classNames('scroll-top scroll-top--glass',
        { 'is-visible': visible },
        { 'is-active': active })}
    >
      <Icon icon="top" className="scroll-top__icon" />
    </Button>
  )
}
