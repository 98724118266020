import * as React from 'react'
import { Breadcrumbs, UnsafeHTML, Image } from 'components'
import { ChipItem } from 'components/chips/chip-item/ChipItem'
import { useHistory } from 'react-router-dom'
import { EventFullRecord } from 'core'
import './style.scss'
import { useTranslation } from 'react-i18next'
import { useLangNavigate } from 'core/hooks/useLangNavigate'

interface ComponentProps {
  event: EventFullRecord
}

const eventImage = require('/app/assets/images/event-description-image.jpeg')

export const EventDescription = (props: ComponentProps) => {
  const { event } = props
  const { title, human_type, human_date, tags, content, item_photo, city } = event

  const history = useHistory()
  const { root } = useLangNavigate()

  const { t } = useTranslation()

  return (
    <div className="event-description">
      <Breadcrumbs
        links={[
          { url: root.events.main, label: t('pages.events.breadcrumbs.label') },
          { label: title },
        ]}
      />
      <div className="event-description__container">
        <div className="event-description__image-block">
          <Image
            src={item_photo.thumb.url || eventImage}
            className="event-description__image"
            alt="event-image"
            placeholderClassName="event-description__image-placeholder"
          />
        </div>
        <div className="event-description__text-block">
          <div>
            <h1 className="event-description__title">{title}</h1>

            <div className="event-description__date-type-block">
              <span className="event-description__type">{human_type}</span>
              <span className="event-description__type">{city ? city.name : 'Online'}</span>
              <span>{human_date}</span>
            </div>

            <UnsafeHTML className="event-description__description" qlEditor={false}>
              {content}
            </UnsafeHTML>
          </div>

          <div>
            {tags.length > 0 ? (
              <>
                <h3 className="event-description__subtitle">{t('pages.events.tags.label')}</h3>
                <div className="event-description__tags-block">
                  {tags.map((tag, index) => (
                    <ChipItem
                      className="event-description__tag"
                      onClick={() => history.push(root.tags.page(tag))}
                      key={index}
                    >
                      {tag.name}
                    </ChipItem>
                  ))}
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}
