import * as React from 'react'
import classnames from 'classnames'
import './style.scss'

interface ComponentProps {
  children: React.ReactNode
  style?: React.CSSProperties
  first?: boolean
  className?: string
  mainWrapperClassName?: string
  wrapperClassName?: string
  element?: 'section' | 'footer' | 'header' | 'div'
}

export const Content = (props: ComponentProps) => {
  const {
    children,
    style,
    first,
    className,
    mainWrapperClassName,
    wrapperClassName,
    element,
  } = props

  const Element = element as keyof JSX.IntrinsicElements

  return (
    <Element
      className={classnames('content', { 'content--first': first }, mainWrapperClassName)}
      style={style}
    >
      <div className={classnames('content__wrapper', wrapperClassName)}>
        <div className={classnames('content__inner', className)}>
          {children}
        </div>
      </div>
    </Element>
  )
}

Content.defaultProps = {
  style: null,
  className: null,
  first: false,
  mainWrapperClassName: null,
  wrapperClassName: null,
  element: 'section',
} as ComponentProps
