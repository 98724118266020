import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useClickOutside from 'core/hooks/useClickOutside'
import { useLang } from 'core/hooks/useLang'
import styles from './lang-switcher.module.scss'

type ILangList = {
  value: string
  label: string
}
const languages: ILangList[] = [
  { value: 'en', label: 'English' },
  { value: 'ru', label: 'Русский' },
]

export const LangSwitcher = () => {
  const { i18n } = useTranslation()
  const { currentLocale, changeLangHandler } = useLang()
  const [open, setIsOpen] = useState<boolean>(false)
  const [language, setLanguage] = useState<string>('ru')
  const myRef = useRef<HTMLInputElement>(null)

  const handleLangChange = async (e) => {
    const lang = e.target.value

    setLanguage(lang)
    await i18n.changeLanguage(lang)
    changeLangHandler()
    setIsOpen(false)
  }

  const closeHandler = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  useClickOutside(myRef, closeHandler)

  useEffect(() => {
    setLanguage(currentLocale.short)
  }, [currentLocale])

  return (
    <div className={styles.wrapper} ref={myRef}>
      <div onClick={() => setIsOpen(true)}>{language}</div>
      <div className={`${styles.list} ${open ? styles.show : styles.hide}`}>
        {languages.map((item: ILangList) => (
          <button
            key={item.value}
            type="button"
            className={styles.item}
            onClick={handleLangChange}
            value={item.value}
          >
            {item.label}
          </button>
        ))}
      </div>
    </div>
  )
}
