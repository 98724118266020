import { CollectionWidths } from 'core'
import { useEffect, useState } from 'react'

export const useEventsInRow = () => {
  const getEventsInRow = () => {
    if (window.matchMedia(`(max-width: ${CollectionWidths.oneEventWidth})`).matches) {
      return 1
    }
    if (window.matchMedia(`(max-width: ${CollectionWidths.twoEventsWidth})`).matches) {
      return 2
    }
    if (window.matchMedia(`(max-width: ${CollectionWidths.threeEventsWidth})`).matches) {
      return 3
    }
    if (window.matchMedia(`(min-width: ${CollectionWidths.threeEventsWidth})`).matches) {
      return 4
    }
    return 0
  }

  const [eventsInRow, setEventsInRow] = useState<number>(getEventsInRow())
  const handleEventsInRow = () => setEventsInRow(getEventsInRow())

  useEffect(() => {
    window.addEventListener('resize', handleEventsInRow)
    return () => window.removeEventListener('resize', handleEventsInRow)
  }, [])

  return eventsInRow
}

export const useSpeakersInRow = () => {
  const getSpeakersInRow = () => {
    if (window.matchMedia(`(max-width: ${CollectionWidths.twoSpeakersWidth})`).matches) {
      return 2
    } if (window.matchMedia(`(max-width: ${CollectionWidths.threeSpeakersWidth})`).matches) {
      return 3
    } if (window.matchMedia(`(max-width: ${CollectionWidths.fourSpeakersWidth})`).matches) {
      return 4
    } if (window.matchMedia(`(max-width: ${CollectionWidths.fiveSpeakersWidth})`).matches) {
      return 5
    } if (window.matchMedia(`(max-width: ${CollectionWidths.sixSpeakersWidth})`).matches) {
      return 6
    } if (window.matchMedia(`(max-width: ${CollectionWidths.sevenSpeakersWidth})`).matches) {
      return 7
    } if (window.matchMedia(`(min-width: ${CollectionWidths.sevenSpeakersWidth})`).matches) {
      return 8
    }
    return 0
  }

  const [speakersInRow, setSpeakersInRow] = useState<number>(getSpeakersInRow())
  const handleSpeakersInRow = () => setSpeakersInRow(getSpeakersInRow())

  useEffect(() => {
    window.addEventListener('resize', handleSpeakersInRow)
    return () => window.removeEventListener('resize', handleSpeakersInRow)
  }, [])

  return speakersInRow
}

export const useArticlesInRow = () => {
  const getArticlesInRow = () => {
    if (window.matchMedia(`(max-width: ${CollectionWidths.oneArticleWidth})`).matches) {
      return 1
    }
    if (window.matchMedia(`(max-width: ${CollectionWidths.twoArticlesWidth})`).matches) {
      return 2
    }
    if (window.matchMedia(`(max-width: ${CollectionWidths.threeArticlesWidth})`).matches) {
      return 3
    }
    if (window.matchMedia(`(min-width: ${CollectionWidths.threeArticlesWidth})`).matches) {
      return 4
    }
    return 0
  }

  const [articlesInRow, setArticlesInRow] = useState<number>(getArticlesInRow())
  const handleArticlesInRow = () => setArticlesInRow(getArticlesInRow())

  useEffect(() => {
    window.addEventListener('resize', handleArticlesInRow)
    return () => window.removeEventListener('resize', handleArticlesInRow)
  }, [])

  return articlesInRow
}

export const globalEventsInRow = () => {
  if (window.matchMedia(`(max-width: ${CollectionWidths.oneGlobalEventWidth})`).matches) {
    return 1
  } if (window.matchMedia(`(min-width: ${CollectionWidths.oneGlobalEventWidth})`).matches) {
    return 2
  }
  return null
}

export const useCoursesInRow = () => {
  const getCoursesInRow = () => {
    if (window.matchMedia(`(min-width: ${CollectionWidths.fourCoursesWith})`).matches) {
      return 4
    }
    if (window.matchMedia(`(min-width: ${CollectionWidths.threeCoursesWith})`).matches) {
      return 3
    }
    if (window.matchMedia(`(min-width: ${CollectionWidths.twoCoursesWith})`).matches) {
      return 2
    }
    return 1
  }

  const [coursesInRow, setCoursesInRow] = useState<number>(getCoursesInRow())
  const handleCoursesInRow = () => setCoursesInRow(getCoursesInRow())

  useEffect(() => {
    window.addEventListener('resize', handleCoursesInRow)
    return () => window.removeEventListener('resize', handleCoursesInRow)
  }, [])

  return coursesInRow
}
