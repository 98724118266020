import * as React from 'react'
import { IdName, SearchState, searchType } from 'core'
import {
  StateFilterComponent,
  TagFilterComponent,
  TextSearchComponent,
} from './filters'
import './style.scss'

interface ComponentProps {
  searchTypes: searchType[]
  onFilterChange: (state: SearchState) => void
  searchState: SearchState
  stateOptions?: IdName[]
  // itemsType?: string
}

export const SearchBlockComponent = ({
  searchTypes, onFilterChange, searchState, stateOptions,
}: ComponentProps) => (
  <div className="search-block">
    { searchTypes.includes('text') ? (
      <>
        <TextSearchComponent
          onChange={(search) => onFilterChange({ ...searchState, search })}
        />
        <div className="search-block__divider" />
      </>
    ) : null }

    { searchTypes.includes('state') ? (
      <>
        <StateFilterComponent
          stateOptions={stateOptions}
          selectedState={searchState.state}
          onChange={(state) => onFilterChange({ ...searchState, state })}
        />
        <div className="search-block__divider" />
      </>
    ) : null }

    { searchTypes.includes('tag') ? (
      <TagFilterComponent
        selectedTag={searchState.tag}
        onChange={(tag) => onFilterChange({ ...searchState, tag })}
      />
    ) : null }
  </div>
)

SearchBlockComponent.defaultProps = {
  stateOptions: [],
}
