import { TFunction } from 'i18next'
import { FormValues } from 'pages/job-posting-page/JobPostingPage.types'

export const getJobLocation = (t: TFunction) => [
  { label: t('pages.job.posting.location.remote'), value: '1' },
  { label: t('pages.job.posting.location.office'), value: '0' },
  { label: t('pages.job.posting.location.optional'), value: '2' },
]

export const getEmploymentTypes = (t: TFunction) => [
  { label: t('pages.job.posting.employmentType.full'), value: 'FULL_TIME' },
  { label: t('pages.job.posting.employmentType.part'), value: 'PART_TIME' },
]

export const getSalaryPeriods = (t: TFunction) => [
  { label: t('pages.job.posting.salaryPeriod.hour'), value: 'HOUR' },
  { label: t('pages.job.posting.salaryPeriod.day'), value: 'DAY' },
  { label: t('pages.job.posting.salaryPeriod.week'), value: 'WEEK' },
  { label: t('pages.job.posting.salaryPeriod.month'), value: 'MONTH' },
  { label: t('pages.job.posting.salaryPeriod.year'), value: 'YEAR' },
]

export const getExperienceTypes = (t: TFunction) => [
  { label: t('pages.job.posting.experienceTypes.without'), value: '0' },
  { label: t('pages.job.posting.experienceTypes.required'), value: '1' },
]

export const getSalaryTypes = (t: TFunction) => [
  { label: t('pages.job.posting.salaryType.interview'), value: '0' },
  { label: t('pages.job.posting.salaryType.fixed'), value: '1' },
]

export const getInitialValues = (t: TFunction): FormValues => ({
  city: '',
  company_name: '',
  company_url: '',
  conditions_md: '',
  contact_email: '',
  contact_tg: '',
  country: '',
  description_md: '',
  duties: '',
  ecosystems: [],
  ecosystems_other: '',
  employmentType: 'FULL_TIME',
  experience: '0',
  experienceAmount: 1,
  intropia_link: '',
  location: '1',
  logo: {
    name: t('pages.job.posting.form.file'),
    type: '',
    size: 0,
  },
  salary: '0',
  salaryPeriod: 'MONTH',
  salary_max: '',
  salary_min: '',
  specialty: [],
  specialty_other: '',
  stack: [],
  stack_other: '',
  title: '',
})
