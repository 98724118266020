import { ModalWindowProps } from 'components'
import { ModalWindowState } from '../types/ModalWindow.types'
import { setError, setSuccess } from '../utils'

export const openModalWindow = (state: ModalWindowState, payload: ModalWindowProps) => {
  if (!payload) {
    return setError(state)
  }

  return setSuccess(state, payload)
}

export const closeModalWindow = (state: ModalWindowState) => {
  const payload: ModalWindowProps = {
    active: false,
    children: null,
  }

  return setSuccess(state, payload)
}
