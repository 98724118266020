import * as React from 'react'
import classNames from 'classnames'
import { ChipItem } from 'components'
import { TagRecord } from 'core'
import { useTranslation } from 'react-i18next'

interface ComponentProps {
  tags: TagRecord[]
  tagsFilters: string[] | null
  onChange: (tags: string[] | null) => void
}

export const TagsFilterComponent = (props: ComponentProps) => {
  const { tags, onChange, tagsFilters } = props

  const [tagsState, setTagsState] = React.useState<string[] | null>(null)
  const [isAllTags, setIsAllTags] = React.useState<boolean>(true)
  const { t } = useTranslation()

  const hasTagsFilters = Boolean(Array.isArray(tagsFilters) && tagsFilters.length)
  const hasTagsState = Boolean(Array.isArray(tagsState) && tagsState.length)

  function addTag(slug: string) {
    setIsAllTags(false)

    if (hasTagsState) {
      setTagsState([...tagsState, slug])
    } else {
      setTagsState([slug])
    }

    if (hasTagsFilters) {
      onChange([...tagsFilters, slug])
    } else {
      onChange([slug])
    }
  }

  function removeTag(slug: string) {
    setTagsState(tagsFilters.filter((tag) => tag !== slug))
    onChange(tagsFilters.filter((tag) => tag !== slug))

    if (tagsState.length === 1) setIsAllTags(true)
  }

  function resetTags() {
    setIsAllTags(true)
    if (hasTagsFilters && hasTagsState) {
      onChange(tagsFilters.filter((tag) => !tagsState.includes(tag)))
    }
    setTagsState(null)
  }

  const onClickHandler = (tagSlug: string) => {
    if (tagSlug === 'all') {
      resetTags()
    } else if (hasTagsFilters && tagsFilters.includes(tagSlug)) {
      removeTag(tagSlug)
    } else {
      addTag(tagSlug)
    }
  }

  const [tagsToShow, setTagsToShow] = React.useState<number>(tags.length < 5 ? tags.length : 5)
  const availableTags = tags.length - tagsToShow
  const chips = [
    { id: 'all', name: t('pages.jobList.filter.all') },
    ...tags.slice(0, tagsToShow).map(({ slug, name }) => ({ id: slug, name })),
  ]

  return (
    <>
      {chips.map((chip) => {
        const tagSlug = chip.id
        let active = false

        if (tagSlug === 'all') {
          active = isAllTags
        } else if (hasTagsState) {
          active = tagsState.includes(tagSlug)
        }

        return (
          <ChipItem
            key={chip.id}
            className={classNames('tag-filter-component__tag', { 'chips__item--active': active })}
            onClick={() => onClickHandler(tagSlug)}
          >
            {chip.name}
          </ChipItem>
        )
      })}

      {tagsToShow < tags.length ? (
        <ChipItem
          className="tag-filter-component__tag tag-filter-component__more-button"
          onClick={() => {
            setTagsToShow(availableTags < 30 ? tagsToShow + availableTags : tagsToShow + 30)
          }}
        >
          {t('pages.jobList.filter.more')}
        </ChipItem>
      ) : null}
    </>
  )
}
