import * as React from 'react'
import { GlobalEventRecord } from 'core'
import { useTranslation } from 'react-i18next'

import { CalendarIcon } from 'components/global-events/item/calendar-icon/calendar-icon'
import { addToCalendar } from 'core/utils/add-to-calendar'
import { reformatDates } from 'core/utils/reformateDates'
import { useLang } from 'core/hooks/useLang'
import styles from './global-item.module.scss'

export const GlobalItem = (event: GlobalEventRecord) => {
  const { background_image, title, city, online, date, uri, calendar_link, side_events } = event
  const { t } = useTranslation()
  const { currentLanguage } = useLang()
  const location = online ? t('pages.events.global.event.online') : city.name

  const formattedDate = reformatDates(date, currentLanguage)
  const openNewWindow = (url: string) => {
    window.open(url, '_blank')
  }
  return (
    <div className={styles.item}>
      <div
        className={styles.image}
        onClick={() => {
          openNewWindow(uri)
        }}
      >
        <img src={background_image?.thumb?.url} alt={`${title} image`} />
      </div>
      <div className={styles.content}>
        <div
          className={styles.date}
          onClick={() => {
            openNewWindow(uri)
          }}
        >
          <div>
            <span>{formattedDate.start.day}</span>
            {formattedDate.start.month}
          </div>
          {!formattedDate.isOneDay && (
            <>
              <div className={styles.delimeter}>-</div>
              <div>
                <span>{formattedDate.end.day}</span>
                {formattedDate.end.month}
              </div>
            </>
          )}
        </div>
        <div className={styles.info}>
          <h2
            onClick={() => {
              openNewWindow(uri)
            }}
          >
            {title}
          </h2>
          <span
            onClick={() => {
              openNewWindow(uri)
            }}
          >
            {location}
          </span>
          <div className={styles.buttons}>
            <div
              className={styles.button}
              onClick={(e) => {
                e.preventDefault()
                {
                  calendar_link
                    ? openNewWindow(calendar_link)
                    : addToCalendar(title, `${title} ${location}`, location, formattedDate.original)
                }
              }}
            >
              <>Add to </>
              <CalendarIcon />
            </div>
            {side_events && (
              <div
                onClick={(e) => {
                  e.preventDefault()
                  openNewWindow(side_events)
                }}
                className={styles.button}
              >
                Side Events
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
