import * as React from 'react'
import axios from 'axios'
import { useRef } from 'react'
import { useAppDispatch } from 'redux/hooks'
import { Formik, Form } from 'formik'
import { FormControl, Button, Breadcrumbs } from 'components'
import { MODAL_OPEN } from 'redux/actions/ModalWindow.actions'
import { setFormikErrors } from 'core/utils'
import { JobPostingModalSuccess, JobPostingModalUnSuccess } from 'modals'
import { CountryRecord } from 'core/records/CountryRecord'
import { countriesApiUrl, useAppSelector } from 'core'
import './style.scss'
import { ecosystemsTags, specialtiesTags, stacksTags } from 'core/features'
import ReactGA from 'react-ga4'
import { useTranslation } from 'react-i18next'
import {
  getEmploymentTypes,
  getExperienceTypes,
  getInitialValues,
  getJobLocation,
  getSalaryPeriods,
  getSalaryTypes,
} from 'pages/job-posting-page/JobPostingPage.config'
import { FormValues } from 'pages/job-posting-page/JobPostingPage.types'
import { getJobPostingFormSchema } from 'pages/job-posting-page/JobPostingPage.schema'
import { useLangNavigate } from 'core/hooks/useLangNavigate'

export const JobPostingPage = () => {
  const dispatch = useAppDispatch()
  const { root } = useLangNavigate()

  const ref = useRef(null)
  const [loading, setLoading] = React.useState<boolean>(false)
  const stacks = useAppSelector(stacksTags).map((stack) => ({
    label: stack.name,
    value: stack.slug,
  }))
  const specialties = useAppSelector(specialtiesTags)
  const ecosystems = useAppSelector(ecosystemsTags)
  const [specialtyOtherIsVisible, setSpecialtyOtherIsVisible] = React.useState<boolean>(false)
  const [stackOtherIsVisible, setStackOtherIsVisible] = React.useState<boolean>(false)
  const [ecosystemOtherIsVisible, setEcosystemOtherIsVisible] = React.useState<boolean>(false)
  const [countries, setCountries] = React.useState<CountryRecord[]>(null)
  const { t } = useTranslation()
  const fetchCountries = () => {
    setLoading(true)
    axios({
      method: 'get',
      url: countriesApiUrl,
    })
      .then((res) => {
        if (res.status >= 200 && res.status <= 210) {
          setCountries(res.data)
          setLoading(false)
        }
      })
      .catch(() => setLoading(false))
  }
  React.useEffect(() => {
    fetchCountries()
  }, [])

  const onTelegramHandler = (e, set) =>
    set(e.target.name, e.target.value.replace(/(@|[^a-zA-Z0-9_])/, ''))
  const sendJob = (values, setFieldError) => {
    setLoading(true)
    const data = new FormData()
    Object.entries(ref.current.values as FormValues).forEach((value) => {
      if (value[0] === 'logo' && value[1].size > 0) {
        data.append('logo', value[1], value[1].name)
      } else if (Array.isArray(value[1]) && value.length) {
        value[1].forEach((arrValue) => {
          data.append(`${value[0]}[]`, arrValue)
        })
      } else if (value[1].length > 0) {
        data.append(value[0], value[1])
      }
    })
    axios
      .post('https://cyberacademy.dev/api/vacancies', data, {
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        },
      })
      .then((response) => {
        setLoading(false)
        if (response.status === 200) {
          ReactGA.event({
            category: 'job_posting',
            action: 'create',
            label: 'new_job_posting',
          })
          dispatch(
            MODAL_OPEN({
              active: true,
              children: <JobPostingModalSuccess contact_email={ref.current.values.contact_email} />,
            }),
          )
        } else {
          dispatch(
            MODAL_OPEN({
              active: true,
              children: <JobPostingModalUnSuccess />,
            }),
          )
        }
      })
      .catch((errors) => {
        setLoading(false)
        if (errors.response.status === 422) setFormikErrors(errors.response.data, setFieldError)
      })
  }

  return (
    <div className="job-posting__wrapper">
      <Breadcrumbs
        links={[
          { url: root.jobs.main, label: t('pages.job.posting.breadcrumbs.label') },
          { label: t('pages.job.posting.breadcrumbs.label2') },
        ]}
      />
      <div className="job-posting">
        <h1>{t('pages.job.posting.heading')}</h1>
        <p>{t('pages.job.posting.description')}</p>
        <Formik
          innerRef={ref}
          initialValues={getInitialValues(t)}
          validationSchema={getJobPostingFormSchema(t)}
          onSubmit={(values, { setFieldError }) => sendJob(values, setFieldError)}
        >
          {({ values, isValid, dirty, setFieldValue }) => (
            <Form className="form job-posting-form">
              <FormControl
                name="company_name"
                control="input"
                labelName={t('pages.job.posting.companyName.label')}
                wrapperClassName="job-posting-form__item"
                required
                tabIndex={0}
              />
              <FormControl
                control="file"
                fileName={values.logo.name}
                labelName={t('pages.job.posting.file.label')}
                labelText={t('pages.job.posting.file.text')}
                name="logo"
                setFieldValue={setFieldValue}
                tabIndex={0}
                wrapperClassName="job-posting-form__item "
              />
              <FormControl
                name="company_url"
                control="input"
                labelName={t('pages.job.posting.url.label')}
                wrapperClassName="job-posting-form__item"
                required
                tabIndex={0}
              />
              <FormControl
                name="title"
                control="input"
                labelName={t('pages.job.posting.jobName.label')}
                wrapperClassName="job-posting-form__item"
                required
                tabIndex={0}
              />
              {specialties && !loading && (
                <FormControl
                  name="speciality"
                  control="checkbox"
                  items={specialties}
                  labelName={t('pages.job.posting.speciality.label')}
                  labelText={t('pages.job.posting.speciality.text')}
                  wrapperClassName="job-posting-form__item job-posting-form__item--checkbox"
                  tabIndex={0}
                  other={specialtyOtherIsVisible}
                  showOtherField={setSpecialtyOtherIsVisible}
                />
              )}
              {specialtyOtherIsVisible && (
                <FormControl
                  name="specialty_other"
                  control="input"
                  labelName={t('pages.job.posting.specialityOther.label')}
                  labelText={t('pages.job.posting.specialityOther.text')}
                  wrapperClassName="job-posting-form__item"
                  tabIndex={0}
                />
              )}
              <FormControl
                name="location"
                control="radio"
                options={getJobLocation(t)}
                labelName={t('pages.job.posting.location.label')}
                wrapperClassName="job-posting-form__item"
                tabIndex={0}
              />
              {!(Number(values.location) === 1) && (
                <div className="job-posting-form__location">
                  {countries && !loading && (
                    <FormControl
                      name="country"
                      control="select"
                      labelName={t('pages.job.posting.country.label')}
                      labelText={t('pages.job.posting.country.text')}
                      placeholder={t('pages.job.posting.country.placeholder')}
                      wrapperClassName="job-posting-form__item"
                      setFieldValue={setFieldValue}
                      options={countries}
                      tabIndex={0}
                      searchable
                    />
                  )}
                  <FormControl
                    name="city"
                    control="input"
                    labelName={t('pages.job.posting.city.label')}
                    labelText={t('pages.job.posting.city.text')}
                    wrapperClassName="job-posting-form__item"
                    disabled={!values.country}
                    tabIndex={0}
                  />
                </div>
              )}
              <FormControl
                name="employmentType"
                options={getEmploymentTypes(t)}
                control="radio"
                labelName={t('pages.job.posting.employmentType.label')}
                wrapperClassName="job-posting-form__item"
                tabIndex={0}
              />
              <FormControl
                name="experience"
                options={getExperienceTypes(t)}
                control="radio"
                labelName={t('pages.job.posting.experienceTypes.label')}
                wrapperClassName="job-posting-form__item"
                tabIndex={0}
              />
              {!!Number(values.experience) && (
                <FormControl
                  name="experienceAmount"
                  control="input"
                  type="number"
                  labelName={t('pages.job.posting.experienceAmount.label')}
                  labelText={t('pages.job.posting.experienceAmount.text')}
                  wrapperClassName="job-posting-form__item job-posting-form__item--experience"
                  // required
                  tabIndex={0}
                  min={0}
                />
              )}
              <FormControl
                name="description_md"
                control="textarea"
                labelName={t('pages.job.posting.descriptionMd.label')}
                labelText={t('pages.job.posting.descriptionMd.text')}
                wrapperClassName="job-posting-form__item"
                required
                tabIndex={0}
              />
              <FormControl
                name="duties"
                control="textarea"
                labelName={t('pages.job.posting.duties.label')}
                wrapperClassName="job-posting-form__item"
                tabIndex={0}
              />
              <FormControl
                name="conditions_md"
                control="textarea"
                labelName={t('pages.job.posting.conditionsMd.label')}
                wrapperClassName="job-posting-form__item"
                tabIndex={0}
              />
              {stacks && !loading && (
                <FormControl
                  name="stack"
                  control="select"
                  options={stacks}
                  labelName={t('pages.job.posting.stack.label')}
                  labelText={t('pages.job.posting.stack.text')}
                  wrapperClassName="job-posting-form__item job-posting-form__item--checkbox"
                  tabIndex={0}
                  other={stackOtherIsVisible}
                  showOtherField={setStackOtherIsVisible}
                  setFieldValue={setFieldValue}
                  searchable
                  isMulti
                />
              )}
              {stackOtherIsVisible && (
                <FormControl
                  name="stack_other"
                  control="input"
                  labelName={t('pages.job.posting.stackOther.label')}
                  labelText={t('pages.job.posting.stackOther.text')}
                  wrapperClassName="job-posting-form__item"
                  tabIndex={0}
                />
              )}
              {ecosystems && !loading && (
                <FormControl
                  name="ecosystems"
                  control="checkbox"
                  items={ecosystems}
                  labelName={t('pages.job.posting.ecosystems.label')}
                  labelText={t('pages.job.posting.ecosystems.text')}
                  wrapperClassName="job-posting-form__item job-posting-form__item--checkbox"
                  other={ecosystemOtherIsVisible}
                  showOtherField={setEcosystemOtherIsVisible}
                />
              )}
              {ecosystemOtherIsVisible && (
                <FormControl
                  name="ecosystems_other"
                  control="input"
                  labelName={t('pages.job.posting.ecosystemsOther.label')}
                  labelText={t('pages.job.posting.ecosystemsOther.text')}
                  wrapperClassName="job-posting-form__item"
                  tabIndex={0}
                />
              )}
              <FormControl
                name="salary"
                control="radio"
                options={getSalaryTypes(t)}
                labelName={t('pages.job.posting.salaryType.label')}
                wrapperClassName="job-posting-form__item"
              />
              {!!Number(values.salary) && (
                <div className="job-posting-form__item">
                  <h2>{t('pages.job.posting.salaryType.heading')}</h2>
                  <p>{t('pages.job.posting.salaryType.text')}</p>
                  <div className="job-posting-form__salary">
                    <FormControl
                      name="salary_min"
                      control="input"
                      type="number"
                      labelText={t('pages.job.posting.salaryType.min')}
                      fieldClassName="job-posting-form__salary-number"
                      hideErrorMessage
                    />
                    <span>-</span>
                    <FormControl
                      name="salary_max"
                      control="input"
                      type="number"
                      labelText={t('pages.job.posting.salaryType.max')}
                      fieldClassName="job-posting-form__salary-number"
                      hideErrorMessage
                    />
                    <div className="job-posting-form__salary-currency">
                      <span>$</span>
                    </div>
                    <FormControl
                      name="salaryPeriod"
                      control="select"
                      labelText={t('pages.job.posting.salaryPeriod.label')}
                      options={getSalaryPeriods(t)}
                      fieldClassName="job-posting-form__salary-select job-posting-form__salary-select--period"
                    />
                  </div>
                </div>
              )}
              <FormControl
                name="contact_email"
                control="input"
                labelName={t('pages.job.posting.email.label')}
                wrapperClassName="job-posting-form__item"
              />
              <FormControl
                control="input"
                name="intropia_link"
                labelName={t('pages.job.posting.intropiaLink.label')}
                wrapperClassName="job-posting-form__item"
                placeholder={t('pages.job.posting.intropiaLink.placeholder')}
              />
              <FormControl
                name="contact_tg"
                control="input"
                labelName={t('pages.job.posting.contactTg.label')}
                wrapperClassName="job-posting-form__item"
                onChangeHandler={(e) => onTelegramHandler(e, setFieldValue)}
              />
              <Button
                className="job-posting-form__submit"
                mode={!(isValid && dirty) ? 'outlined' : 'contained'}
                type="submit"
                disabled={loading}
              >
                {loading
                  ? t('pages.job.posting.button.sending')
                  : t('pages.job.posting.button.normal')}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
      <div className="job-posting__ellipse job-posting__ellipse--1" />
      <div className="job-posting__ellipse job-posting__ellipse--2" />
    </div>
  )
}
