import * as React from 'react'
import { EventPanelItem, ShowMoreLink } from 'components'
import { EventRecord } from 'core'
import { useEventsInRow } from 'core/utils'
import './style.scss'
import { useTranslation } from 'react-i18next'

interface ComponentProps {
  title: string
  events: EventRecord[]
  showMoreURL?: string
}

export const EventsSection = (props: ComponentProps) => {
  const { title, events, showMoreURL } = props
  const eventsInRow = useEventsInRow()
  const { t } = useTranslation()

  if (events.length === 0) {
    return null
  }

  return (
    <div className="event-item-events">
      <h3 className="event-item-page__heading">{title}</h3>

      <div
        className={
          events.length < eventsInRow
            ? 'collection-centered__event-container'
            : 'collection__event-container'
        }
      >
        {events.map((event) => (
          <EventPanelItem centered={events.length < eventsInRow} key={event.id} event={event} />
        ))}
      </div>

      {showMoreURL ? (
        <ShowMoreLink
          url={showMoreURL}
          style={{
            margin: '0 auto',
          }}
        >
          {t('general.EventsSection.moreEvents')}
        </ShowMoreLink>
      ) : null}
    </div>
  )
}

EventsSection.defaultProps = {
  showMoreURL: null,
}
