import * as React from 'react'
import { Redirect, Switch, Route } from 'react-router-dom'
import { Layout, ModalWindow } from 'components'

import {
  HomePage,
  GlobalEventsPage,
  AboutUsPage,
  ArticlesPage,
  EventsPage,
  SpeakersPage,
  JobsPage,
  NotFoundPage,
  EventItemPage,
  SpeakerPage,
  ArticlePage,
  TagPage,
  JobPage,
  JobPostingPage,
  CoursesPage,
  CoursePage,
} from 'pages'
import ReactGA from 'react-ga4'
import { useAppSelector } from 'core'
import { useEffect } from 'react'
import { useLangNavigate } from 'core/hooks/useLangNavigate'
import { useLang } from 'core/hooks/useLang'
import { useScrollOnNavigate } from './hooks'

export const AppRouter = () => {
  const { root } = useLangNavigate()
  const { detectLangHandler } = useLang()

  useScrollOnNavigate()
  const GA_ID = useAppSelector((state) => state.appData.static.gtag)

  useEffect(() => {
    ReactGA.initialize(GA_ID)
  }, [GA_ID])

  useEffect(() => {
    detectLangHandler()
  }, [])

  return (
    <Layout>
      <ModalWindow />
      <Switch>
        <Route exact path={root.main}>
          <HomePage />
        </Route>
        <Route path="/en" exact render={() => <Redirect to="/" />} />
        <Route path="/en/" exact render={() => <Redirect to="/" />} />
        <Route path={root.events.main} exact>
          <EventsPage />
        </Route>
        <Route path="/en/events" exact render={() => <Redirect to="/events" />} />
        <Route path={`${root.events.main}/:slug`}>
          <EventItemPage />
        </Route>
        <Route path="/en/events/:slug" exact render={() => <Redirect to="/events/:slug" />} />
        <Route path={root.speakers.main} exact>
          <SpeakersPage />
        </Route>

        <Route path="/en/speakers" exact render={() => <Redirect to="/speakers" />} />
        <Route path={`${root.speakers.main}/:slug`} exact>
          <SpeakerPage />
        </Route>
        <Route path="/en/speakers/:slug" exact render={() => <Redirect to="/speakers/:slug" />} />
        <Route path={`${root.tags.main}/:slug`}>
          <TagPage />
        </Route>
        <Route path="/en/tags/" exact render={() => <Redirect to="/tags" />} />
        <Route path="/en/tags/:slug" exact render={() => <Redirect to="/tags/:slug" />} />

        <Route path={root.blog.main} exact>
          <ArticlesPage />
        </Route>
        <Route path="/en/blog/" exact render={() => <Redirect to="/blog" />} />

        <Route path={`${root.blog.main}/:slug`}>
          <ArticlePage />
        </Route>
        <Route path="/en/blog/:slug" exact render={() => <Redirect to="/blog/:slug" />} />

        <Route path={root.about}>
          <AboutUsPage />
        </Route>
        <Route path="/en/about-us" exact render={() => <Redirect to="/about-us" />} />

        <Route path={root.events.global}>
          <GlobalEventsPage />
        </Route>
        <Route path="/en/global-events" exact render={() => <Redirect to="/global-events" />} />

        <Route path={root.jobs.create}>
          <JobPostingPage />
        </Route>
        <Route path="/en/jobs/create" exact render={() => <Redirect to="/jobs/create" />} />

        <Route path={root.jobs.main} exact>
          <JobsPage />
        </Route>
        <Route path="/en/jobs" exact render={() => <Redirect to="/jobs" />} />

        <Route path={`${root.jobs.main}/:slug`}>
          <JobPage />
        </Route>
        <Route path="/en/jobs/:slug" exact render={() => <Redirect to="/jobs/:slug" />} />

        <Route path={root.courses.main} exact>
          <CoursesPage />
        </Route>
        <Route path="/en/learn" exact render={() => <Redirect to="/learn" />} />

        <Route path={`${root.courses.main}/:slug`}>
          <CoursePage />
        </Route>
        <Route path="/en/learn/:slug" exact render={() => <Redirect to="/learn/:slug" />} />

        <Route path="*">
          <NotFoundPage />
        </Route>
      </Switch>
    </Layout>
  )
}
