import * as React from 'react'
import { Link } from 'react-router-dom'
import {
  ApiCollectionResponseMeta,
  IdName,
  SearchState,
  searchType,
  TagRecord,
  useAppSelector,
} from 'core'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import {
  ArticleItemPlaceholder,
  EventPanelItemPlaceholder,
  SpeakerItemPlaceholder,
} from 'components'
import { selectTags } from 'core/features'
import { useArticlesInRow, useEventsInRow, tagBySlug, useSpeakersInRow } from 'core/utils'
import { useLangNavigate } from 'core/hooks/useLangNavigate'
import { SearchBlockComponent } from './search-block/SearchBlockComponent'
import './style.scss'

interface ComponentProps {
  heading: string
  collection: any[]
  itemType: 'event' | 'speaker' | 'article' | 'jobs'
  renderItem: (any?, number?) => React.ReactNode
  searchTypes?: searchType[]
  onFilterChange?: (SearchState) => void
  searchState: SearchState
  stateOptions?: IdName[]
  loading?: boolean
  responseMeta?: ApiCollectionResponseMeta
  metaLink?: { label: string; href: string }
  placeholderRows?: number
}

export const SearchableComponent = (props: ComponentProps) => {
  const tags = useAppSelector(selectTags)
  const { root } = useLangNavigate()

  const {
    heading,
    collection,
    itemType,
    renderItem,
    searchTypes,
    onFilterChange,
    searchState,
    stateOptions,
    loading,
    responseMeta,
    metaLink,
    placeholderRows,
  } = props

  const { t } = useTranslation()

  let PlaceholderComponent
  let elementsInRow

  switch (itemType) {
    case 'event':
      PlaceholderComponent = EventPanelItemPlaceholder
      // eslint-disable-next-line react-hooks/rules-of-hooks
      elementsInRow = useEventsInRow()
      break
    case 'speaker':
      PlaceholderComponent = SpeakerItemPlaceholder
      // eslint-disable-next-line react-hooks/rules-of-hooks
      elementsInRow = useSpeakersInRow()
      break
    case 'article':
      PlaceholderComponent = ArticleItemPlaceholder
      // eslint-disable-next-line react-hooks/rules-of-hooks
      elementsInRow = useArticlesInRow()
      break
    default:
      return null
  }

  let placeholders = elementsInRow * placeholderRows

  if (responseMeta?.totalCount != null) {
    placeholders = Math.min(responseMeta.totalCount - collection.length, placeholders)
  }

  let tag: TagRecord

  if (searchState.tag) {
    tag = tagBySlug(tags, searchState.tag)
  }

  return (
    <>
      <h1 className="searchable-component__heading">{heading}</h1>

      {searchTypes.length > 0 ? (
        <SearchBlockComponent
          searchState={searchState}
          searchTypes={searchTypes}
          onFilterChange={onFilterChange}
          stateOptions={stateOptions}
        />
      ) : null}

      {responseMeta ? (
        <div
          className={classNames('searchable-component__number-of-items-container', {
            'searchable-component__number-of-items-container--active':
              responseMeta?.totalCount != null,
          })}
        >
          <p
            className={classNames('searchable-component__number-of-items', {
              'searchable-component__number-of-items--with-meta-link': metaLink,
            })}
          >
            <b>{responseMeta.totalCount}</b> {responseMeta.humanTotalCount}{' '}
            {tag ? (
              <>
                {t('general.SearchableComponent.inCat')}
                <Link to={root.tags.page(tag)}>{tag.name}</Link>
              </>
            ) : (
              t('general.SearchableComponent.inAllCats')
            )}
          </p>
          {metaLink ? (
            <div className="searchable-component__meta-link-container">
              <div className="searchable-component__divider" />
              <a
                href={metaLink.href}
                target="_blank"
                rel="noreferrer"
                className="searchable-component__meta-link"
              >
                {metaLink.label}
              </a>
            </div>
          ) : null}
        </div>
      ) : null}

      <div className={`collection__${itemType}-container`}>
        {collection.map(renderItem)}

        {/* TODO: use records.length here */}
        {loading
          ? new Array(placeholders)
              .fill(null)
              .map((value, index) => <PlaceholderComponent key={index} />)
          : null}
      </div>
    </>
  )
}

SearchableComponent.defaultProps = {
  searchTypes: ['text', 'tag'],
  onFilterChange: (_state) => null,
  stateOptions: [],
  loading: false,
  responseMeta: null,
  metaLink: null,
  placeholderRows: null,
} as ComponentProps
