import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { BackgroundEllipse, Content } from 'components'
import { speakerApiUrl, UserFullRecord } from 'core'
import { useApiResource } from 'core/hooks/useApiResource'
import { ArticlesSection, EventsSection, SpeakersSection, VideosSection } from 'pages/shared'
import { SpeakerDescription } from './sections'
import './style.scss'

export const SpeakerPage = () => {
  const { resource, loading } = useApiResource<UserFullRecord>(speakerApiUrl, {
    titleKey: 'full_name',
  })
  const { t } = useTranslation()

  return (
    <div className="speaker">
      <Content first className="speaker-page">
        {!loading ? (
          <>
            <SpeakerDescription speaker={resource} />

            <VideosSection
              videos={resource.videos}
              title={t('pages.speaker.VideosSection.heading')}
            />

            <EventsSection
              events={resource.events}
              title={t('pages.speaker.EventsSection.heading')}
            />

            <ArticlesSection
              title={t('pages.speaker.ArticlesSection.heading')}
              articles={resource.all_posts}
            />

            <SpeakersSection
              speakers={resource.related_speakers}
              title={t('pages.speaker.SpeakersSection.heading')}
            />
          </>
        ) : (
          <h2
            style={{
              color: '#ffffff',
            }}
          >
            {t('pages.speaker.loading')}
          </h2>
        )}

        <BackgroundEllipse color="blue" className="speaker-page__figure-1" />
        <BackgroundEllipse color="green-1" className="speaker-page__figure-2" />
        <BackgroundEllipse color="blue" className="speaker-page__figure-3" />
      </Content>
    </div>
  )
}
