import * as React from 'react'
import { Field, ErrorMessage } from 'formik'
import classNames from 'classnames'
import { FormControlOptions } from '../interfaces'

export const RadioButtons: React.FC<FormControlOptions> = ({
  name,
  labelName,
  labelText,
  wrapperClassName,
  required,
  options,
  tabIndex,
}: FormControlOptions) => (
  <div className={classNames('form-control', wrapperClassName, { required })}>
    {labelName && <h2>{labelName}</h2>}
    {labelText && <p>{labelText}</p>}
    <div className="form-control-radio">
      {options.map((item, index) => (
        <label
          htmlFor={`${name}-${item.value}`}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              (event.target as HTMLInputElement).click()
            }
          }}
          tabIndex={tabIndex}
          className="form-control-radio__item"
          key={index}
        >
          <Field
            id={`${name}-${item.value}`}
            type="radio"
            name={name}
            tabIndex={-1}
            value={item.value}
          />
          <div className="chip-item">
            <span>{item.label}</span>
          </div>
        </label>
      ))}
    </div>
    <ErrorMessage name={name} className="form-control__error" component="div" />
  </div>
)

RadioButtons.defaultProps = {
  options: [],
}
