import * as React from 'react'

import { Button } from 'components/button/Button'
import { EventRecord } from 'core'
import { SetStateAction } from 'react'
import { useHistory } from 'react-router-dom'
import cx from 'classnames'
import css from './UpComingEvent.module.scss'

interface ComponentProps {
  events: EventRecord[]
  show: boolean
  setShow: React.Dispatch<SetStateAction<boolean>>
}

export const UpComingEvent = ({ events, show, setShow }: ComponentProps) => {
  const [event, setEvent] = React.useState<EventRecord | null>(null)
  const history = useHistory()

  function setUpComingEventsToLocalStorage(UpComingEvents) {
    UpComingEvents.forEach((i) => {
      const ls = JSON.parse(localStorage.getItem(`ShowUpComingEvent-${i.id}`))

      if (ls === null) localStorage.setItem(`ShowUpComingEvent-${i.id}`, JSON.stringify(true))
    })
  }

  function fetchUpComingEvent(UpComingEvents) {
    UpComingEvents.every((item) => {
      const ls = JSON.parse(localStorage.getItem(`ShowUpComingEvent-${item.id}`))

      if (ls === true) {
        setEvent(item)
        setShow(true)

        return false
      }

      return true
    })
  }

  function disableUpComingEvent(id) {
    localStorage.setItem(`ShowUpComingEvent-${id}`, JSON.stringify(false))
  }

  const learnMore = (ev) => {
    setShow(false)
    disableUpComingEvent(ev.id)
    setEvent(null)
    history.push(`/events/${ev.id}-${ev.slug}`)
  }

  React.useEffect(() => {
    setUpComingEventsToLocalStorage(events)
    fetchUpComingEvent(events)
  }, [events])

  return (
    <>
      {show
        && (
        <div className={cx(css.upcoming_event)}>
          <div className={cx(css.upcoming_event__wrapper)}>
            <div className={cx(css.upcoming_event__content)}>
              <div className={cx(css.upcoming_event__info)}>
                <span>{`📅 ${event.human_date}`}</span>
                <span>{`📍 ${event.city ? event.city.name : 'Online'}`}</span>
              </div>
              <h2>{event.title}</h2>
              <Button
                className={cx(css.upcoming_event__content_more)}
                mode="outlined"
                onClick={() => learnMore(event)}
              >
                <span>Learn more</span>
              </Button>
            </div>
          </div>
        </div>
        )}
    </>
  )
}
