import * as React from 'react'
import { useAppSelector } from 'core'
import { selectInfoPartners, selectSponsors } from 'core/features'
import './style.scss'
import { Button } from 'components'
import { useTranslation } from 'react-i18next'

export const Sponsors = () => {
  const sponsors = useAppSelector(selectSponsors)
  const infoPartners = useAppSelector(selectInfoPartners)

  const { t } = useTranslation()

  return (
    <section className="sponsors">
      <h2 className="sponsors__heading-1">{t('pages.home.sponsors.heading')}</h2>

      <div className="sponsors__sponsors-block">
        {sponsors.map((sponsor) => (
          <span className="sponsors__sponsor-logo" key={sponsor.id}>
            <a href={sponsor.url} target="_blank" rel="noreferrer">
              <img src={sponsor.logo.thumb.url} alt="logo" />
            </a>
          </span>
        ))}
      </div>

      <h3 className="sponsors__heading-2">{t('pages.home.sponsors.heading2')}</h3>

      <div className="sponsors__info-partners-block">
        {infoPartners.map((sponsor) => (
          <span className="sponsors__info-partner-logo" key={sponsor.id}>
            <a href={sponsor.url} target="_blank" rel="noreferrer">
              <img src={sponsor.logo.thumb.url} alt="logo" />
            </a>
          </span>
        ))}
      </div>

      <Button href="https://airtable.com/shrYnuMUlCnZazyoc" mode="contained">
        {t('pages.home.sponsors.button')}
      </Button>
    </section>
  )
}
