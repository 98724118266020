import * as React from 'react'
import * as Yup from 'yup'

import axios from 'axios'
import { FormControl, Button } from 'components'
import { Form, Formik } from 'formik'
import { setFormikErrors } from 'core/utils'
import { useAppDispatch } from 'redux/hooks'
import { MODAL_OPEN } from 'redux/actions/ModalWindow.actions'
import cx from 'classnames'
import ReactGA from 'react-ga4'
import css from './JobApplyModal.module.scss'

interface ComponentProps {
  id: number
  jobTitle: string
}

export const JobApplyModal = (props: ComponentProps) => {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = React.useState<boolean>(false)
  const ref = React.useRef(null)
  const { id, jobTitle } = props
  const initialValues = {
    user_cv: {
      name: 'Выбирите файл',
      type: '',
      size: 0,
    },
    user_email: '',
    user_message: '',
    user_name: '',
  }

  const JobApplyFormSchema = Yup.object().shape({
    user_name: Yup.string()
      .required('Пожалуйста, укажите свое имя!'),
    user_email: Yup.string()
      .email('Некорректный email')
      .required('Пожалуйста, укажите email'),
    user_message: Yup.string()
      .min(40, 'Сообщение не должно быть короче 40 символов')
      .required('Пожалуйста, оставьте сообщение!'),
  })

  const sendJobApply = (setFieldError) => {
    setLoading(true)
    const data = new FormData()
    const { user_cv } = ref.current.values
    if (user_cv.size > 0 && user_cv.type === ('application/pdf' || 'application/msword')) {
      data.append('user_cv', user_cv, user_cv.name)
    }
    data.append('user_email', ref.current.values.user_email)
    data.append('user_message', ref.current.values.user_message)
    data.append('user_name', ref.current.values.user_name)
    axios.patch(`https://cyberacademy.dev/api/vacancies/${id}`, data, {
      headers: {
        'X-CSRF-Token': document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute('content'),
      },
    }).then((response) => {
      setLoading(false)
      if (response.status >= 200 && response.status <= 210) {
        ReactGA.event(
          {
            action: 'send',
            category: 'job_apply',
            label: 'email_apply',
          },
        )
        dispatch(MODAL_OPEN({
          active: true,
          children: <h3>Ваш отклик на вакансию отпрален!</h3>,
        }))
      } else {
        dispatch(MODAL_OPEN({
          active: true,
          children: <h3>Что то пошло не так.. Попробуйте позже</h3>,
        }))
      }
    }).catch((errors) => {
      setLoading(false)
      if (errors.response.status === 422) {
        setFormikErrors(errors.response.data, setFieldError)
      }
    })
  }

  return (
    <>
      <h2>{jobTitle}</h2>
      <Formik
        initialValues={initialValues}
        innerRef={ref}
        validationSchema={JobApplyFormSchema}
        onSubmit={(values, { setFieldError }) => sendJobApply(setFieldError)}
      >
        {({
          values, setFieldValue, isValid, dirty,
        }) => (
          <Form className={cx(css.form)}>
            <FormControl
              name="user_name"
              control="input"
              wrapperClassName=""
              placeholder="Имя:"
              required
              tabIndex={0}
            />
            <FormControl
              name="user_email"
              control="input"
              placeholder="Email:"
              required
              tabIndex={0}
            />
            <FormControl
              name="user_message"
              control="textarea"
              placeholder="Сообщение:"
              tabIndex={0}
            />
            <FormControl
              name="user_cv"
              control="file"
              tabIndex={0}
              setFieldValue={setFieldValue}
              fileName={values.user_cv.name}
              labelText="Прикрепить CV"
            />
            <Button
              mode={!(isValid && dirty) ? 'outlined' : 'contained'}
              type="submit"
              disabled={loading}
            >
              {loading ? 'Отправляем Ваш отклик..' : 'Отправить'}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  )
}
