import * as React from 'react'
import { NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import { Content } from 'components'
import classNames from 'classnames'
import './style.scss'
import { useTranslation } from 'react-i18next'
import { LangSwitcher } from 'components/lang-switcher/LangSwitcher'
import styles from './styles.module.scss'
import { useLangNavigate } from 'core/hooks/useLangNavigate'

const logo = require('/app/assets/images/logo.svg')

export const Header = () => {
  const [menuActive, setMenuActive] = React.useState<boolean>(false)
  const { root } = useLangNavigate()

  const isArticlePath = useRouteMatch<boolean | null>(`${root.blog.main}/:slug`)
  const isLessonPath = useRouteMatch<boolean | null>(`${root.courses.main}/:slug`)

  const { t } = useTranslation()

  React.useEffect(() => {
    document.body.classList.toggle('app__overflow-hidden', menuActive)
  }, [menuActive])

  const history = useHistory()

  const links: { uri: string; label: string; external?: boolean }[] = [
    {
      uri: root.main,
      label: 'general.menu.home',
    },
    {
      uri: root.events.main,
      label: 'general.menu.events',
    },
    {
      uri: root.courses.main,
      label: 'general.menu.courses',
    },
    {
      uri: root.speakers.main,
      label: 'general.menu.speakers',
    },
    {
      uri: root.blog.main,
      label: 'general.menu.articles',
    },
    {
      uri: root.about,
      label: 'general.menu.about',
    },
    {
      uri: root.events.global,
      label: 'general.menu.calendar',
    },
    {
      uri: root.jobs.main,
      label: 'general.menu.vacancies',
    },
  ]

  return (
    <Content
      element="header"
      mainWrapperClassName={classNames('header', {
        'header--no-fixed': isArticlePath || isLessonPath,
      })}
      className="header__content"
    >
      <button className="header__logo-button" type="button" onClick={() => history.push(root.main)}>
        <img src={logo} alt="logo" className="header__logo" />
      </button>
      <nav className="header__links-block">
        {links.map((link) => {
          if (link.external) {
            return (
              <a
                key={link.uri}
                href={link.uri}
                target="_blank"
                rel="noreferrer"
                className="header__link"
              >
                {t(link.label)}
              </a>
            )
          }

          if (link.uri === root.courses.main) {
            return (
              <NavLink
                key={link.uri}
                className="header__link header__link--new"
                activeClassName="header__active-link"
                to={link.uri}
              >
                {t(link.label)}
              </NavLink>
            )
          }

          // TODO: remove this, when JobBoard is done
          // now it show info that /jobs is beta
          if (link.uri === root.jobs.main) {
            return (
              <NavLink
                key={link.uri}
                className="header__link header__link--beta"
                activeClassName="header__active-link"
                to={link.uri}
              >
                {t(link.label)}
              </NavLink>
            )
          }

          return (
            <NavLink
              key={link.uri}
              exact={link.uri === '/'}
              className="header__link"
              activeClassName="header__active-link"
              to={link.uri}
            >
              {t(link.label)}
            </NavLink>
          )
        })}
      </nav>

      <div className={styles.wrapper}>
        <button
          onClick={() => setMenuActive(!menuActive)}
          className={classNames('header__burger-button', { 'is-active': menuActive })}
          type="button"
          aria-label="Menu"
          aria-controls="navigation"
        >
          <span className="header__burger-button-box">
            <span className="header__burger-button-inner" />
          </span>
        </button>

        <div
          className={classNames('header__mobile-menu', {
            'header__mobile-menu--active': menuActive,
          })}
        >
          {links.map((link) => {
            if (link.external) {
              return (
                <a
                  key={link.uri}
                  href={link.uri}
                  target="_blank"
                  rel="noreferrer"
                  className="header__mobile-link"
                  onClick={() => setMenuActive(false)}
                >
                  {t(link.label)}
                </a>
              )
            }

            if (link.uri === root.courses.main) {
              return (
                <NavLink
                  key={link.uri}
                  className="header__mobile-link header__link--new"
                  activeClassName="header__active-link"
                  to={link.uri}
                  onClick={() => setMenuActive(false)}
                >
                  {t(link.label)}
                </NavLink>
              )
            }

            // TODO: remove this, when JobBoard is done
            // now it show info that /jobs is beta
            if (link.uri === root.jobs.main) {
              return (
                <NavLink
                  key={link.uri}
                  className="header__mobile-link header__link--beta"
                  activeClassName="header__active-link"
                  to={link.uri}
                  onClick={() => setMenuActive(false)}
                >
                  {t(link.label)}
                </NavLink>
              )
            }

            return (
              <NavLink
                key={link.uri}
                exact={link.uri === '/'}
                className="header__mobile-link"
                activeClassName="header__active-link"
                to={link.uri}
                onClick={() => setMenuActive(false)}
              >
                {t(link.label)}
              </NavLink>
            )
          })}
        </div>
        <LangSwitcher />
      </div>
    </Content>
  )
}
