import * as React from 'react'
import { BackgroundEllipse, Content } from 'components'
import { CyberAcademy, Events, OurTeam, Sponsors, HomeArticles } from './sections'
import './style.scss'

export const HomePage = () => {
  const [eventsCount, setEventsCount] = React.useState<number>(0)

  return (
    <div className="home">
      <Content element="div" className="home-page__content">
        <CyberAcademy eventsCount={eventsCount} />
        <Events counter={setEventsCount} />
        <HomeArticles />
        <OurTeam />
        <Sponsors />
        <BackgroundEllipse color="green-1" className="home-page__ellipse-1" />
        <BackgroundEllipse color="blue" className="home-page__ellipse-2" />
      </Content>
    </div>
  )
}
