import * as React from 'react'
import { Content } from 'components'
import { useTranslation } from 'react-i18next'

export const NotFoundPage = () => {
  const { t } = useTranslation()

  return (
    <Content
      first
      style={{
        color: '#ffffff',
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <h1>{t('pages.notfound.heading')}</h1>
    </Content>
  )
}
