import * as React from 'react'
import { Breadcrumbs, Image, UnsafeHTML } from 'components'
import { ChipItem } from 'components/chips/chip-item/ChipItem'
import { useHistory } from 'react-router-dom'
import { UserFullRecord } from 'core'
import './style.scss'
import { useTranslation } from 'react-i18next'
import { useLangNavigate } from 'core/hooks/useLangNavigate'

interface ComponentProps {
  speaker: UserFullRecord
}

const avatarPlaceholder = require('/app/assets/images/avatar-placeholder.jpeg')

export const SpeakerDescription = (props: ComponentProps) => {
  const { speaker } = props

  const { full_name, tags, work_place, company, avatar, description } = speaker

  const { root } = useLangNavigate()
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <div className="speaker-description">
      <Breadcrumbs
        links={[
          { url: root.speakers.main, label: t('pages.speaker.breadcrumbs.label') },
          { label: full_name },
        ]}
      />
      <div className="speaker-description__container">
        <div className="speaker-description__image-block">
          <Image
            src={avatar.large.url ? avatar.large.url : avatarPlaceholder}
            className="speaker-description__image"
            alt="event-image"
            placeholderClassName="speaker-description__image-placeholder"
          />
        </div>
        <div className="speaker-description__text-block">
          <div>
            <h2 className="speaker-description__title">{full_name}</h2>

            <div className="speaker-description__date-type-block">
              <span>
                {work_place}
                {company ? (
                  <>
                    {work_place ? ' @ ' : ''}
                    <a href={company.url} target="_blank" rel="noreferrer">
                      {company.name}
                    </a>
                  </>
                ) : null}
              </span>
            </div>

            {description ? (
              <UnsafeHTML className="speaker-description__description">{description}</UnsafeHTML>
            ) : null}
          </div>

          {tags.length > 0 ? (
            <div>
              <h3 className="speaker-description__subtitle">Теги</h3>

              <div className="event-description__tags-block">
                {tags.map((tag, index) => (
                  <ChipItem
                    className="speaker-description__tag"
                    onClick={() => history.push(root.tags.page(tag))}
                    key={index}
                  >
                    {tag.name}
                  </ChipItem>
                ))}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}
