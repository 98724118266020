import * as React from 'react'
import { Icon } from 'components'
import { IconName } from 'core'
import classNames from 'classnames'
import './style.scss'

interface ComponentProps {
  icon: IconName
  className?: string
  onClick: () => void
}

export const IconButton = (props: ComponentProps) => {
  const { icon, className, onClick } = props

  return (
    <button
      type="button"
      onClick={onClick}
      className={classNames('icon-button', className)}
    >
      <Icon icon={icon} className="icon-button__icon" />
    </button>
  )
}

IconButton.defaultProps = {
  className: null,
} as ComponentProps
