import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Content, GlobalEventItemPlaceholder } from 'components'
import { globalBG, GlobalEventRecord, globalEventsApiUrl } from 'core'
import { globalEventsInRow } from 'core/utils'
import { useApiCollection } from 'core/hooks/useApiCollection'
import './style.scss'

import {
  filterEventsByCityIds,
  getAllCities,
  IFilters,
  separateByMonth,
} from 'pages/global-events-page/helpers/global-events-page.logic'
import { GlobalEventFilters } from 'pages/global-events-page/global-event-filters/global-event-filters'

import { GlobalEventGroup } from 'components/global-events/group/global-group'
import { useLang } from 'core/hooks/useLang'
import styles from './global-events-page.module.scss'

export const GlobalEventsPage = () => {
  const [separatedEvents, setSeparatedEvents] = useState([])
  const [filters, setFilters] = useState<IFilters[]>([])
  const [selectedCities, setSelectedCities] = useState<Set<number>>(new Set())
  const {
    state: { loading, records },
  } = useApiCollection<GlobalEventRecord>(globalEventsApiUrl)

  const { t } = useTranslation()
  const { currentLanguage } = useLang()

  const placeholderRows = 3
  const placeholders = globalEventsInRow() * placeholderRows

  useEffect(() => {
    if (records?.length) {
      const citiesList = getAllCities(records)
      setFilters(citiesList)
    }
  }, [records, t])

  const handleCityFilter = (cityId: number) => {
    const newSelectedCities = new Set(selectedCities)
    if (newSelectedCities.has(cityId)) {
      newSelectedCities.delete(cityId)
    } else {
      newSelectedCities.add(cityId)
    }
    setSelectedCities(newSelectedCities)
  }

  useEffect(() => {
    if (selectedCities.size > 0 && records) {
      const data = filterEventsByCityIds(records, Array.from(selectedCities))
      const separated = separateByMonth(data.records, currentLanguage)
      setSeparatedEvents(separated)
    } else {
      const separated = separateByMonth(records, currentLanguage)
      setSeparatedEvents(separated)
    }
  }, [records, selectedCities, t])

  return (
    <div className="global-events">
      <Content first className="global-events-page">
        <div className={styles.header}>
          <h2 className="global-events-page__heading">{t('pages.events.global.heading')}</h2>
          <div className={styles.addEvent}>
            <a
              className="button button--outlined jobs-page__heading-button"
              href="https://airtable.com/shrNsct4mVYlZqYHk"
              rel="noreferrer"
              target="_blank"
            >
              {t('pages.events.global.add.buttonText')}
            </a>
          </div>
          <GlobalEventFilters data={filters} onCityFilter={handleCityFilter} />
        </div>

        <div className={styles.groups}>
          {Array.isArray(separatedEvents) &&
            separatedEvents.map((group) => (
              <GlobalEventGroup key={group?.label} data={group?.records} title={group?.label} />
            ))}
        </div>

        <div className={styles.container}>
          {loading
            ? new Array(placeholders)
                .fill(null)
                .map((value, index) => <GlobalEventItemPlaceholder key={index} />)
            : null}
        </div>
      </Content>
      <img className={styles.bg} src={globalBG} alt="global-bg" />
    </div>
  )
}
