import {
  ContentLink,
  CityRecord,
  TagRecord,
  UserRecord,
  SponsorRecord, PostRecord,
} from 'core'

export interface EventType {
  id: number
  name: string
}

export interface EventVideo {
  id: number
  video_id: string
}

export interface EventRecord {
  id: number
  title: string
  slug: string
  item_image: ContentLink<'thumb'>
  online: boolean
  city: CityRecord
  human_date: string
  human_type: string
}

export interface EventFullRecord extends EventRecord {
  background_image: ContentLink<'thumb'>
  item_photo: ContentLink<'thumb'>
  content: string
  photos_url: string
  photos: ContentLink<'thumb'>[]
  tags: TagRecord[]
  speakers: UserRecord[]
  sponsors: SponsorRecord[]
  info_partners: SponsorRecord[]
  posts: PostRecord[]
  related_events: EventRecord[]
  event_videos: EventVideo[]
}

export const humanEventInfo = ({ human_date, human_type, city }: EventRecord): string => [
  human_date,
  human_type,
  city ? city.name : 'Online',
].join(' | ')
