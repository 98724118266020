import * as React from 'react'
import './style.scss'

export const ArticleItemPlaceholder = () => (
  <div className="article-item-placeholder">
    <div className="article-item-placeholder__image" />
    <div className="article-item-placeholder__text-container">
      <div className="article-item-placeholder__date-author" />
      <div className="article-item-placeholder__title" />
      <div className="article-item-placeholder__title" />
    </div>
  </div>
)
