import * as React from 'react'
import { useTranslation } from 'react-i18next'

interface ComponentProps {
  error: string | null
}

export const JobPostingModalUnSuccess = (props: ComponentProps) => {
  const { error } = props

  const { t } = useTranslation()
  return (
    <>
      <h2>{t('pages.job.posting.modals.wrong.heading')}</h2>
      {error ? (
        <>
          <p style={{ color: '#FF5631' }}>{error}</p>
          <p>
            {t('pages.job.posting.modals.wrong.heading')}
            <a href="https://airtable.com/shrpUQSpVulGxnLt7" target="_blank" rel="noreferrer">
              AirTable
            </a>
            .
          </p>
        </>
      ) : (
        <>
          <p>{t('pages.job.posting.modals.wrong.error')}</p>
          <p>
            {t('pages.job.posting.modals.wrong.help')}
            <a href="https://airtable.com/shrpUQSpVulGxnLt7" target="_blank" rel="noreferrer">
              AirTable
            </a>
            .
          </p>
          <p>{t('pages.job.posting.modals.wrong.techError')}</p>
        </>
      )}
      <p>
        {t('pages.job.posting.modals.wrong.writeEmail')}
        <a href="mailto:info@cyberacademy.dev">info@cyberacademy.dev</a>
      </p>
    </>
  )
}

JobPostingModalUnSuccess.defaultProps = {
  error: null,
} as ComponentProps
