import { PaginationState, SearchState } from 'core'
import { ajax } from 'rxjs/ajax'
import { first, map, Observable } from 'rxjs'

export const pathWithParams = (
  uri: string,
  searchState: SearchState = {},
  pagination: PaginationState = null,
) => {
  if (Object.keys(searchState).length === 0 && pagination == null) {
    return uri
  }

  const filterData = Object.entries({ ...searchState, ...(pagination || {}) })
    .filter(([key, value]) => value !== null)
    .map(([key, value]) => {
      if (Array.isArray(value)) return `${key}[]=${Object.values(value).join(`&${key}[]=`)}`
      return `${key}=${value}`
    })
    .join('&')

  return `${uri}?${filterData}`
}

export const handleError = (error: any, history): void => {
  if (error.status === 404) {
    history.replace('/page-not-found')
    return
  }

  throw error
}

export const apiRequest = <T>(url): Observable<T> =>
  ajax.get<T>(url).pipe(
    first(),
    map((response) => {
      if (response.status < 200 && response.status > 210) {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw response
      }

      return response
    }),
    map((response) => response.response),
  )
