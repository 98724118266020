// TODO: refactor later all panels (event, article, course, lesson)
//  to one recycled component of panel-item and placeholder-panel-item
// TODO: add number of lesson
import * as React from 'react'
import { Link } from 'react-router-dom'
import { LessonRecord } from 'core'
import { Image } from 'components'
import { useAppDispatch } from 'redux/hooks'
import { DisabledItemModal } from 'modals'
import { MODAL_OPEN } from 'redux/actions/ModalWindow.actions'
import './style.scss'
import { useTranslation } from 'react-i18next'

interface ComponentProps {
  lesson: LessonRecord
  url: string
  pagination?: boolean
}

export const LessonItem = (props: ComponentProps) => {
  const dispatch = useAppDispatch()
  const { lesson, url, pagination } = props
  const { t } = useTranslation()
  const { cover, title, duration, active, number } = lesson

  const showModal = () => {
    dispatch(
      MODAL_OPEN({
        active: true,
        children: <DisabledItemModal itemType="Урок" title={title} />,
      }),
    )
  }

  const ItemContent = (
    <>
      <Image
        src={cover.thumb.url}
        className="lesson-item__image"
        alt={title}
        placeholderClassName="lesson-item__image-placeholder"
      />
      <div className="lesson-item__wrapper">
        {pagination ? (
          <div className="lesson-item__number">
            <span>{t('pages.course.lesson.item.label', { number })}</span>
          </div>
        ) : (
          <div className="lesson-item__duration">
            {duration && (
              <>
                <span>{t('pages.course.lesson.item.duration')}</span>
                <span>{duration}</span>
              </>
            )}
          </div>
        )}
        <h2 className="lesson-item__title">{title}</h2>
      </div>
    </>
  )

  if (active) {
    return (
      <Link to={url} className="lesson-item">
        {ItemContent}
      </Link>
    )
  }

  return (
    <div className="lesson-item lesson-item--disabled" onClick={showModal}>
      {ItemContent}
    </div>
  )
}

LessonItem.defaultProps = {
  pagination: false,
} as ComponentProps
