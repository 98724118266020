import * as React from 'react'
import { ChipsComponent } from 'components'
import { IdName } from 'core'
import './style.scss'

interface ComponentProps {
  selectedState: string
  onChange: (state: string) => void
  stateOptions: IdName[]
}

export const StateFilterComponent = (props: ComponentProps) => {
  const { onChange, selectedState, stateOptions } = props

  return (
    <ChipsComponent
      chips={stateOptions}
      selectedId={selectedState}
      contained
      className="date-filter-component"
      onClick={onChange}
    />
  )
}
