import * as React from 'react'
import { Button } from 'components'
import { useAppDispatch } from 'redux/hooks'
import { MODAL_CLOSE } from 'redux/actions/ModalWindow.actions'
import { string } from 'prop-types'
import { useTranslation } from 'react-i18next'

export const JobPostingModalSuccess = ({ contact_email }) => {
  const dispatch = useAppDispatch()
  const isContactEmail = Boolean(contact_email && contact_email.length)
  const { t } = useTranslation()
  return (
    <>
      <h2>{t('pages.job.posting.modals.success.heading')}</h2>
      {isContactEmail && <p>{t('pages.job.posting.modals.success.heading')}</p>}
      <p>
        {t('pages.job.posting.modals.success.description')}
        <a href="mailto:info@cyberacademy.dev">info@cyberacademy.dev</a>
      </p>
      <p>{t('pages.job.posting.modals.success.warning')}</p>
      <Button mode="contained" onClick={() => dispatch(MODAL_CLOSE())}>
        {t('pages.job.posting.modals.success.button')}
      </Button>
    </>
  )
}

JobPostingModalSuccess.propTypes = {
  contact_email: string,
}

JobPostingModalSuccess.defaultProps = {
  contact_email: '',
}
