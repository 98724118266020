import * as React from 'react'
import { Header, UpComingEvent, Footer } from 'components'
import { EventRecord, useAppSelector } from 'core'
import { selectUpcomingEvents } from 'core/features'
import classNames from 'classnames'

interface ComponentProps {
  children: React.ReactNode
}

export const Layout = ({ children }: ComponentProps) => {
  const upComingEvents = useAppSelector<EventRecord[]>(selectUpcomingEvents)
  const [topBarShow, setTopBarShow] = React.useState<boolean>(false)

  return (
    <>
      <Header />
      <main className={classNames('', { 'main--with-top-bar': topBarShow })}>
        {upComingEvents.length > 0
          && <UpComingEvent events={upComingEvents} show={topBarShow} setShow={setTopBarShow} />}
        {children}
      </main>
      <Footer />
    </>
  )
}
