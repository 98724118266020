import * as React from 'react'
import classNames from 'classnames'
import { VideoItemPlaceholder } from 'components'
import './style.scss'

interface ComponentProps {
  video: string
  centered?: boolean
}

export const VideoItem = (props: ComponentProps) => {
  const { video, centered } = props

  const [loading, setLoading] = React.useState<boolean>(true)

  return (
    <>
      <div className={classNames('video-item', { 'video-item--loading': loading }, { 'video-item--centered': centered })}>
        <iframe
          className="video-item__iframe"
          onLoad={() => setLoading(false)}
          src={`https://www.youtube.com/embed/${video}`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
        <div />
      </div>
      { loading ? (
        <VideoItemPlaceholder centered={centered} />
      ) : null }
    </>
  )
}

VideoItem.defaultProps = {
  centered: false,
}
