import * as React from 'react'
import { useArticlesInRow } from 'core/utils'
import { useApiCollection } from 'core/hooks/useApiCollection'
import { PostRecord, articlesApiUrl } from 'core'
import {
  ArticleItem,
  ArticleItemPlaceholder,
  // SearchableComponent,
  ShowMoreLink,
} from 'components'
import { useTranslation } from 'react-i18next'
import { useLangNavigate } from 'core/hooks/useLangNavigate'

// interface ComponentProps {
//   className?: string
//   search?: boolean
// }

// TODO Refactor this HomeArticles section to Articles component
// with refactored SearchableComponent inside
// and use on Articles Page

export const HomeArticles = () => {
  const { root } = useLangNavigate()

  const articlesRenderQuantity = useArticlesInRow() * 2
  const { state } = useApiCollection<PostRecord>(articlesApiUrl, {}, articlesRenderQuantity)
  const { records, loading } = state
  // const {
  //   className, search,
  // } = props

  const { t } = useTranslation()

  return (
    <section className="home__articles">
      <h2>{t('pages.home.article.heading')}</h2>
      <p>{t('pages.home.article.description')}</p>
      <div className="home__articles-items collection__article-container">
        {records.map((article, index) => (
          <ArticleItem key={index} article={article} />
        ))}
        {loading
          ? new Array(records.length)
              .fill(null)
              .map((value, index) => <ArticleItemPlaceholder key={index} />)
          : null}
      </div>
      <ShowMoreLink url={root.blog.main}>{t('pages.home.article.button')}</ShowMoreLink>
    </section>
  )
}

// HomeArticles.defaultProps = {
//   className: null,
//   search: false,
// }
