import * as React from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import './style.scss'

interface ComponentProps
  extends React.HTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode
  mode: 'contained' | 'outlined' | 'default'
  className?: string
  type?: 'button' | 'reset' | 'submit'
  onClick?: () => void
  href?: string
  to?: string
  spShowForm?: string
  ariaLabel?: string
  disabled?: boolean
}

export const Button = (props: ComponentProps) => {
  const {
    onClick,
    to,
    href,
    children,
    mode,
    spShowForm,
    className,
    type,
    ariaLabel,
    disabled,
  } = props

  if (href) {
    return (
      <a
        className={classnames('button', `button--${mode}`, className)}
        href={href}
        target="_blank"
        rel="noreferrer"
        aria-label={ariaLabel}
      >
        {children}
      </a>
    )
  }

  if (to) {
    return (
      <Link
        className={classnames('button', `button--${mode}`, className)}
        to={to}
      >
        {children}
      </Link>
    )
  }

  if (mode === 'default') {
    return (
      <button
        onClick={onClick}
        type="button"
        className={className}
        aria-label={ariaLabel}
        disabled={disabled}
      >
        {children}
      </button>
    )
  }

  /* eslint-disable react/button-has-type */
  return (
    <button
      onClick={onClick}
      type={type}
      className={classnames('button', `button--${mode}`, className)}
      sp-show-form={spShowForm}
      aria-label={ariaLabel}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

Button.defaultProps = {
  className: null,
  type: 'button',
  onClick: null,
  onMouseLeave: null,
  onMouseEnter: null,
  to: null,
  href: null,
  spShowForm: null,
  ariaLabel: null,
  disabled: false,
} as ComponentProps
