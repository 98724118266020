import * as React from 'react'
import classNames from 'classnames'
import { useApiResource } from 'core/hooks/useApiResource'
import { lessonApiUrl, LessonFullRecord } from 'core'
import {
  Breadcrumbs,
  Content,
  ContentAuthors,
  ContentTextPlaceholder,
  CourseNavigation,
  CourseNavigationPlaceholder,
  Image,
  LessonPagination,
  LightboxImages,
  ScrollTop,
  TopBar,
  UnsafeHTML,
} from 'components'
import './style.scss'
import { useTranslation } from 'react-i18next'
import { useLangNavigate } from 'core/hooks/useLangNavigate'

export const LessonPage = () => {
  const { resource, loading } = useApiResource<LessonFullRecord>(lessonApiUrl)
  const { root } = useLangNavigate()

  const [isMobile, setIsMobile] = React.useState<boolean>(true)
  const [lessonHeadVisible, setLessonHeadVisible] = React.useState<boolean>(false)

  const { t } = useTranslation()

  const toggleLessonHead = () => {
    if (isMobile) setLessonHeadVisible(!lessonHeadVisible)
  }

  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 1185)
  }, [])

  React.useEffect(() => {
    if (!loading) {
      if (!isMobile) document.body.querySelector('.header').classList.remove('header--no-fixed')
    }
  }, [loading])

  return (
    <div className="lesson">
      {!loading && isMobile && (
        <TopBar
          title={resource.title}
          visible={lessonHeadVisible}
          toggle={toggleLessonHead}
          backUrl={root.courses.main}
          backText={t('pages.course.lesson.backText')}
        />
      )}
      <Content first className="lesson-page" mainWrapperClassName="lesson-page__wrapper">
        <div className="lesson-page__main">
          {!loading ? (
            <div className="lesson-page__sidebar">
              <Breadcrumbs
                links={[
                  { url: root.courses.main, label: t('pages.course.lesson.courses') },
                  { label: !loading ? resource.title : null },
                ]}
              />
              <CourseNavigation
                visible={lessonHeadVisible}
                lessons={resource.lessons}
                toggle={toggleLessonHead}
                courseSlug={resource.course.slug}
              />
            </div>
          ) : (
            <CourseNavigationPlaceholder />
          )}
          <div className="lesson-page__content">
            {!loading ? (
              resource.course.header_image && (
                <div className="lesson-page__course-image">
                  <Image
                    src={resource.course.header_image.thumb.url}
                    alt={resource.course.title}
                    placeholderClassName="lesson-page__course-image-placeholder"
                  />
                </div>
              )
            ) : (
              <div className="lesson-page__course-image--placeholder" />
            )}
            <div
              className={classNames(
                !loading ? 'lesson-page__title' : 'lesson-page__title--placeholder',
              )}
            >
              {!loading ? <h1>{resource.title}</h1> : null}
            </div>
            <div
              className={classNames(
                !loading ? 'lesson-page__duration' : 'lesson-page__duration-placeholder',
              )}
            >
              <span>
                {!loading && t('pages.course.lesson.duration', { duration: resource.duration })}
              </span>
            </div>
            <ContentAuthors
              authors={!loading ? resource.authors : null}
              loading={loading}
              type="lesson"
            />
            {!loading ? (
              resource.intro && (
                <UnsafeHTML className="lesson-page__intro" qlEditor={false}>
                  {resource.intro}
                </UnsafeHTML>
              )
            ) : (
              <ContentTextPlaceholder times={3} />
            )}
            {!loading ? (
              resource.video && (
                <div className="lesson-page__video">
                  <iframe
                    src={`https://www.youtube.com/embed/${resource.video}`}
                    title={resource.title}
                    width="690"
                    height="388"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div>
              )
            ) : (
              <div className="lesson-page__video-placeholder" />
            )}
            {!loading ? (
              resource.content && (
                <UnsafeHTML className="lesson-page__content" qlEditor={false}>
                  {resource.content}
                </UnsafeHTML>
              )
            ) : (
              <ContentTextPlaceholder times={3} />
            )}
            <LessonPagination loading={loading} resource={resource} />
          </div>
        </div>
      </Content>
      <ScrollTop />
      <div className="lesson__circles" />
      {!loading && <LightboxImages images={resource.images} />}
    </div>
  )
}
