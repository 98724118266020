import * as React from 'react'
import { Button, Content, JobItem, JobItemPlaceholder, ScrollTop, SideBar } from 'components'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { hasNextPage, useApiCollection } from 'core/hooks/useApiCollection'
import { jobsApiUrl, JobRecord, useAppSelector } from 'core'
import { vacanciesHumanCount } from 'core/features'
import './style.scss'
import { useTranslation } from 'react-i18next'
import { useLangNavigate } from 'core/hooks/useLangNavigate'

export const JobsPage = () => {
  const { state, onNextPage, onFilterChange } = useApiCollection<JobRecord>(jobsApiUrl, {
    location: 'all',
    employment: 'all',
  })

  const { records, meta, loading, filters } = state
  const { root } = useLangNavigate()

  const vacanciesAllCount = useAppSelector(vacanciesHumanCount)
  const vacanciesRenderCount = !loading
    ? `${meta.totalCount} ${meta.humanTotalCount}`.toString()
    : vacanciesAllCount
  const { t } = useTranslation()

  const [renderToScrollRef] = useInfiniteScroll({
    loading,
    hasNextPage: hasNextPage(state),
    onLoadMore: onNextPage,
    disabled: false,
    rootMargin: '0px 0px 2% 0px',
    delayInMs: 500,
  })

  return (
    <Content mainWrapperClassName="jobs-page__content" className="jobs-page" first>
      <div className="jobs-page__container">
        <div className="jobs-page__heading">
          <h1>{t('pages.jobList.heading')}</h1>
          <p>{t('pages.jobList.description', { vacanciesAllCount: meta.totalCount })}</p>
          <Button
            className="jobs-page__heading-button"
            mode="outlined"
            to={root.jobs.create}
            aria-label={t('pages.jobList.addButton')}
          >
            {t('pages.jobList.addButton')}
          </Button>
        </div>
        <div className="jobs-page__wrapper">
          <SideBar
            filters={filters}
            onFilterChange={onFilterChange}
            count={String(`${meta.totalCount} ${t('pages.jobList.countString')}`)}
          />
          <div className="jobs-page__items">
            {state.records.map((item) => (
              <JobItem renderRef={renderToScrollRef} key={item.id} job={item} />
            ))}
            {loading
              ? new Array(records.length)
                  .fill(null)
                  .map((value, index) => <JobItemPlaceholder key={index} />)
              : null}
          </div>
        </div>
      </div>
      <ScrollTop />
    </Content>
  )
}
