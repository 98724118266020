import * as React from 'react'
import {
  BackgroundEllipse,
  Breadcrumbs,
  Button,
  ChipItem,
  Content,
  JobItem,
  JobItemPlaceholder,
  JobLogo,
  UnsafeHTML,
} from 'components'
import { jobApiUrl, JobFullRecord } from 'core'
import { useApiResource } from 'core/hooks/useApiResource'
import { useAppDispatch } from 'redux/hooks'
import { MODAL_OPEN } from 'redux/actions/ModalWindow.actions'
import { JobApplyModal } from 'modals'
import './style.scss'
import ReactGA from 'react-ga4'
import { useTranslation } from 'react-i18next'
import { useLangNavigate } from 'core/hooks/useLangNavigate'

export const JobPage = () => {
  const dispatch = useAppDispatch()
  const { resource, loading } = useApiResource<JobFullRecord>(jobApiUrl)
  const { t } = useTranslation()
  const { root } = useLangNavigate()

  const applyJobTelegram = () => {
    ReactGA.event({
      action: 'click',
      category: 'job_apply',
      label: 'telegram_apply',
    })
    window.open(`https://t.me/${resource.contact_tg}`, '_blank')
  }

  const applyJob = () => {
    if (resource.intropia_link) {
      ReactGA.event({
        action: 'click',
        category: 'job_apply',
        label: 'intropia_apply',
      })
      const url = `${new URL(
        resource.intropia_link,
      )}?utm_source=cyberacademy&utm_medium=jobs&utm_campaign=${resource.id}`
      window.open(url, '_blank')

      return
    }
    dispatch(
      MODAL_OPEN({
        active: true,
        children: (
          <JobApplyModal
            id={resource.id}
            jobTitle={`${resource.title} в ${resource.company_name}`}
          />
        ),
      }),
    )
  }

  if (loading) {
    return <h1>Loading...</h1>
  }

  let jobLocation = ''
  if (resource.remote) {
    jobLocation = 'pages.job.type.remote'
    if (resource.country) jobLocation = `${resource.country} ${t('pages.job.type.both')}`
    if (resource.country && resource.city)
      jobLocation = `${resource.country}, ${resource.city} ${t('pages.job.type.both')}`
  } else {
    jobLocation = 'pages.job.type.office'
    if (resource.country) jobLocation = `${resource.country}`
    if (resource.country && resource.city) jobLocation = `${resource.country}, ${resource.city}`
  }

  return (
    <div className="job">
      <Content first className="job-page">
        <Breadcrumbs
          links={[
            { url: root.jobs.main, label: t('pages.job.breadcrumbs.label') },
            { label: `${resource.company_name}` },
          ]}
        />

        <div className="job-page-content">
          <div className="job-page-content__header">
            <JobLogo
              imageUrl={resource.logo ? resource.logo.thumb.url : null}
              companyName={resource.company_name}
              className="job-page-content__header-logo"
            />
            <h1>{`${resource.title} ${t('pages.job.in')} ${resource.company_name}`}</h1>
          </div>

          <div className="job-page-content__sup">
            <div className="job-page-content__sup-item">
              <h2>{t('pages.job.salary')}</h2>
              <ChipItem className="chip-item--info">{resource.human_salary}</ChipItem>
            </div>
            <div className="job-page-content__sup-item">
              <h2>{t('pages.job.location')}</h2>
              <ChipItem className="chip-item--info">{t(jobLocation)}</ChipItem>
            </div>
            <div className="job-page-content__sup-item">
              <h2>{t('pages.job.experience')}</h2>
              <ChipItem className="chip-item--info">{resource.human_experience}</ChipItem>
            </div>
          </div>

          <div className="job-page-content__description">
            <h2>{t('pages.job.shortDescription')}</h2>
            <UnsafeHTML className="job-page-content__description-item" qlEditor={false}>
              {!loading ? resource.description : null}
            </UnsafeHTML>

            {resource.duties && (
              <>
                <h2>{t('pages.job.responsibilities')}</h2>
                <UnsafeHTML className="job-page-content__description-item" qlEditor={false}>
                  {!loading ? resource.duties : null}
                </UnsafeHTML>
              </>
            )}

            {resource.conditions && (
              <>
                <h2>{t('pages.job.conditions')}</h2>
                <UnsafeHTML className="job-page-content__description-item" qlEditor={false}>
                  {!loading ? resource.conditions : null}
                </UnsafeHTML>
              </>
            )}
          </div>

          <div className="job-page-content__sub">
            {resource.tags.length > 0 && (
              <>
                <h2>{t('pages.job.requirements')}</h2>
                <div className="chips job-page-content__requirements">
                  {resource.tags.map((tag) => (
                    <ChipItem className="chip-item--info" key={tag.slug}>
                      {tag.name}
                    </ChipItem>
                  ))}
                </div>
              </>
            )}

            <h2>{t('pages.job.website')}</h2>
            <a
              className="job-page-content__link"
              href={resource.company_url}
              target="_blank"
              rel="noreferrer"
            >
              {resource.company_url}
            </a>

            <div className="job-page-content__apply">
              {resource.contact_tg && (
                <Button
                  className="button job-page-content__tg"
                  mode="outlined"
                  onClick={() => applyJobTelegram()}
                >
                  {t('pages.job.telegram')}
                </Button>
              )}

              {(resource.contact_email || resource.intropia_link) && (
                <Button
                  className="button button--contained"
                  mode="default"
                  onClick={() => applyJob()}
                >
                  {t('pages.job.apply')}
                </Button>
              )}
            </div>
          </div>
        </div>
        <div className="job-page__more-wrapper">
          <h2>{t('pages.job.similar')}</h2>
          <div className="job-page__more">
            {!loading &&
              resource.related_vacancies.map((job) => <JobItem job={job} key={job.id} />)}
            {loading
              ? new Array(4).fill(null).map((value, index) => <JobItemPlaceholder key={index} />)
              : null}
          </div>
        </div>
      </Content>
      <BackgroundEllipse className="job__ellipse job__ellipse--1" color="blue" />
      <BackgroundEllipse className="job__ellipse job__ellipse--2" color="green-1" />
    </div>
  )
}
