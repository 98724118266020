import * as React from 'react'
import { JobRecord } from 'core/records'
import { Icon, JobLogo } from 'components'
import { Link } from 'react-router-dom'
import './style.scss'
import { useLangNavigate } from 'core/hooks/useLangNavigate'

interface ComponentProps {
  job: JobRecord
  renderRef?: any
}

export const JobItem = ({ job, renderRef }: ComponentProps) => {
  const {
    company_name,
    hot,
    human_employment,
    human_experience,
    human_remote,
    human_salary,
    logo,
    tags,
    title,
  } = job
  const { root } = useLangNavigate()

  return (
    <Link ref={renderRef} to={root.jobs.page(job)} className="job-item">
      <div className="job-item-header">
        <JobLogo
          imageUrl={logo ? logo.thumb.url : null}
          companyName={company_name}
          className="job-item-header__logo"
        />
        <div className="job-item-header__company">
          <span>{company_name}</span>
        </div>
        <div className="job-item-header__title">
          <span>{title}</span>
        </div>
      </div>
      <div className="job-item__info">
        {human_employment && <span>{human_employment}</span>}
        <span>{human_remote}</span>
        <span>{human_experience}</span>
        <span>{human_salary}</span>
      </div>
      {tags.length > 0 && (
        <div className="job-item__tags">
          {tags.map((item, index) => (
            <div
              className="job-item__tag chip-item chip-item--contained chips__item--contained-active chips__item--active"
              key={index}
            >
              {item.name}
            </div>
          ))}
        </div>
      )}
      {hot && (
        <div className="job-item__hot">
          <Icon icon="hot" />
        </div>
      )}
    </Link>
  )
}

JobItem.defaultProps = {
  renderRef: null,
}
