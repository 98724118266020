import * as React from 'react'
import classNames from 'classnames'
import './style.scss'

interface ComponentProps {
  src: string
  alt: string
  className?: string
  placeholderClassName?: string
  style?: React.CSSProperties
}

export const Image = (props: ComponentProps) => {
  const {
    src,
    alt,
    className,
    placeholderClassName,
    style,
  } = props

  const [imageLoading, setImageLoading] = React.useState<boolean>(true)

  return (
    <>
      <img
        src={src}
        onLoadStart={() => setImageLoading(true)}
        onLoad={() => setImageLoading(false)}
        alt={alt}
        className={classNames(className, 'image', { 'image--loading': imageLoading })}
        style={style}
      />

      { (placeholderClassName && imageLoading) ? (
        <div className={classNames('image__placeholder', placeholderClassName)} />
      ) : null }
    </>
  )
}

Image.defaultProps = {
  className: null,
  placeholderClassName: null,
  style: null,
} as ComponentProps
