import * as React from 'react'
import { Link } from 'react-router-dom'
import { CourseRecord } from 'core'
import { Image } from 'components'
import { useAppDispatch } from 'redux/hooks'
import { DisabledItemModal } from 'modals'
import { MODAL_OPEN } from 'redux/actions/ModalWindow.actions'
import './style.scss'
import { useLangNavigate } from 'core/hooks/useLangNavigate'

interface ComponentProps {
  course: CourseRecord
}

export const CourseItem = (props: ComponentProps) => {
  const dispatch = useAppDispatch()
  const { course } = props
  const { root } = useLangNavigate()

  const { cover, title, active } = course

  const showModal = () => {
    dispatch(
      MODAL_OPEN({
        active: true,
        children: <DisabledItemModal itemType="Курс" title={title} />,
      }),
    )
  }

  const ItemContent = (
    <>
      <Image
        src={cover.thumb.url}
        className="course-item__image"
        alt={title}
        placeholderClassName="course-item__image-placeholder"
      />
      <h2 className="course-item__title">{title}</h2>
    </>
  )

  if (active) {
    return (
      <Link to={root.courses.page(course)} className="course-item">
        {ItemContent}
      </Link>
    )
  }

  return (
    <div className="course-item course-item--disabled" onClick={showModal}>
      {ItemContent}
    </div>
  )
}
