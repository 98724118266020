import * as React from 'react'
import {
  ellipseBlue, ellipseGreen1, ellipseGreen2, ellipseOrange,
} from 'core'

interface ComponentProps {
  color: 'blue' | 'green-1' | 'green-2' | 'orange'
  className?: string
}

export const BackgroundEllipse = (props: ComponentProps) => {
  const { className, color } = props

  let ellipse
  switch (color) {
    default:
      ellipse = ellipseBlue
      break
    case 'blue':
      ellipse = ellipseBlue
      break
    case 'orange':
      ellipse = ellipseOrange
      break
    case 'green-1':
      ellipse = ellipseGreen1
      break
    case 'green-2':
      ellipse = ellipseGreen2
  }

  return (
    <img
      className={className}
      src={ellipse}
      alt={`ellipse ${color}`}
    />
  )
}

BackgroundEllipse.defaultProps = {
  className: null,
} as ComponentProps
