import * as React from 'react'
import { Field, ErrorMessage } from 'formik'
import classNames from 'classnames'
import { FormControlCheckboxes } from '../interfaces'

export const CheckboxGroup: React.FC<FormControlCheckboxes> = ({
  name,
  labelName,
  labelText,
  wrapperClassName,
  required,
  items = [],
  other,
  showOtherField,
}: FormControlCheckboxes) => (
  <div className={classNames('form-control', wrapperClassName, { required })}>
    {labelName && <h2>{labelName}</h2>}
    {labelText && <p>{labelText}</p>}
    <div className="form-control-checkboxes">
      {items.map((item, index) => (
        <div className="form-control-checkboxes__item" key={index}>
          <Field
            id={`${item.id}-${item.slug}`}
            type="checkbox"
            name={name}
            value={item.slug}
          />
          <label
            tabIndex={0}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                (event.target as HTMLInputElement).click()
              }
            }}
            htmlFor={`${item.id}-${item.slug}`}
            className="chip-item"
          >
            {item.name}
          </label>
        </div>
      ))}
      {(other != null)
        && (
          <div className="form-control-checkboxes__item form-control-checkboxes__item--other" key={items.length + 1}>
            <div
              tabIndex={0}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  (event.target as HTMLInputElement).click()
                }
              }}
              onClick={() => showOtherField(!other)}
              className={classNames('chip-item', { active: other })}
            >
              other
            </div>
          </div>
        )}
      <ErrorMessage name={name} className="form-control__error" component="div" />
    </div>
  </div>
)
