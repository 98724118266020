import * as React from 'react'
import classNames from 'classnames'
import { IdName } from 'core'
import { ChipItem } from './chip-item/ChipItem'
import './style.scss'

interface ComponentProps {
  chips: IdName[]
  onClick?: (chipId: number | string) => void
  selectedId?: string
  className?: string
  chipClassName?: string
  contained?: boolean
  withoutWrapper?: boolean
}

export const ChipsComponent = (props: ComponentProps) => {
  const {
    chips, className, chipClassName, contained, onClick, selectedId, withoutWrapper,
  } = props

  if (onClick) {
    return (
      !withoutWrapper ? (
        <div className={classNames('chips', { 'chips--contained': contained }, className)}>
          {chips.map((chip) => {
            const active = selectedId === chip.id
            return (
              <ChipItem
                key={chip.id}
                className={classNames(chipClassName, {
                  'chips__item--contained-active': contained && active,
                  'chips__item--active': active,
                })}
                onClick={() => {
                  onClick(chip.id)
                }}
                contained={contained}
              >
                {chip.name}
              </ChipItem>
            )
          })}
        </div>
      ) : (
        <>
          {chips.map((chip) => {
            const active = selectedId === chip.id
            return (
              <ChipItem
                key={chip.id}
                className={classNames(chipClassName, {
                  'chips__item--contained-active': contained && active,
                  'chips__item--active': active,
                })}
                onClick={() => {
                  onClick(chip.id)
                }}
                contained={contained}
              >
                {chip.name}
              </ChipItem>
            )
          })}
        </>
      )
    )
  }

  return (
    !withoutWrapper ? (
      <div className={classNames('chips', { 'chips--contained': contained }, className)}>
        {chips.map((chip) => (
          <ChipItem
            key={chip.id}
            className={classNames(chipClassName)}
            contained={contained}
          >
            {chip.name}
          </ChipItem>
        ))}
      </div>
    ) : (
      <>
        {chips.map((chip) => (
          <ChipItem
            key={chip.id}
            className={classNames(chipClassName)}
            contained={contained}
          >
            {chip.name}
          </ChipItem>
        ))}
      </>
    )
  )
}

ChipsComponent.defaultProps = {
  chipClassName: null,
  contained: false,
  selectedId: null,
  withoutWrapper: false,
} as ComponentProps
