import * as React from 'react'
import { ContentAuthor, ContentAuthorPlaceholder } from 'components'
import { UserRecord } from 'core'
import './style.scss'

export interface ContentAuthorProps {
  authors: UserRecord[]
  loading: boolean
  type: string
}

export const ContentAuthors = (props: ContentAuthorProps) => {
  const { loading, type, authors } = props
  if (!loading) {
    return (
      <div className="content-authors">
        {authors.map((author) => (
          <ContentAuthor
            type={type}
            author={author}
            key={author.id}
          />
        ))}
      </div>
    )
  }
  return <ContentAuthorPlaceholder />
}
