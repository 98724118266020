import * as React from 'react'
import { Link } from 'react-router-dom'
import { Image } from 'components'
import { PostRecord } from 'core'
import classNames from 'classnames'
import './style.scss'
import { useTranslation } from 'react-i18next'
import { useLangNavigate } from 'core/hooks/useLangNavigate'

interface ComponentProps {
  article: PostRecord
  centered?: boolean
  sentryRef?: any
}

export const ArticleItem = ({ article, sentryRef, centered }: ComponentProps) => {
  const { background_image, human_date, user, title } = article
  const { t } = useTranslation()
  const { root } = useLangNavigate()

  const translatedAlt = t('pages.article.ImgAlt')
  const articleImgAlt = `${translatedAlt} ${user.full_name}`

  return (
    <Link
      ref={sentryRef}
      className={classNames('article-item', { 'article-item--centered': centered })}
      to={root.blog.page(article)}
    >
      <Image
        src={background_image.thumb.url}
        className="article-item__image"
        alt={articleImgAlt}
        placeholderClassName="article-item__image-placeholder"
      />

      <div className="article-item__text-container">
        <div className="article-item__date-author">{`${human_date} | ${user.full_name}`}</div>
        <h3 className="article-item__title">{title}</h3>
      </div>
    </Link>
  )
}

ArticleItem.defaultProps = {
  centered: false,
  sentryRef: null,
}
