import { RefObject, useEffect } from 'react'

type IClick = (ref: RefObject<any>, handler: (event: Event) => void, isActive?: boolean) => void

const useClickOutside: IClick = (ref, handler, isActive = true) => {
  useEffect(() => {
    function handleClickOutside(event: Event) {
      if (ref.current && !ref.current.contains(event.target)) {
        handler(event)
      }
    }

    if (isActive) {
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }
    document.removeEventListener('mousedown', handleClickOutside)

    return null
  }, [ref, isActive, handler])
}

export default useClickOutside
