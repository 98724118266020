import * as React from 'react'
import Lightbox from 'react-image-lightbox'
import { useEventsInRow } from 'core/utils'
import { PhotoItem, ShowMoreLink } from 'components'
import { ContentLink } from 'core'
import './style.scss'
import { useTranslation } from 'react-i18next'

interface ComponentProps {
  photos: ContentLink<'thumb'>[]
  photosUrl: string
}

export const PhotosSection = ({ photosUrl, photos }: ComponentProps) => {
  const eventsInRow = useEventsInRow()
  const [isOpened, setIsOpened] = React.useState<boolean>(false)
  const [photoIndex, setPhotoIndex] = React.useState<number>(0)

  const { t } = useTranslation()

  return (
    <section className="event-item-photos">
      <h3 className="event-item-page__heading">{t('general.PhotosSection.title')}</h3>

      <div
        className={
          photos.length < eventsInRow
            ? 'collection-centered__event-container'
            : 'collection__event-container'
        }
      >
        {photos.map((photo, index) => (
          <PhotoItem
            centered={photos.length < eventsInRow}
            key={photo.url}
            src={photo.url}
            onClick={() => {
              setPhotoIndex(index)
              setIsOpened(true)
            }}
          />
        ))}
      </div>

      {photosUrl ? (
        <ShowMoreLink
          style={{
            marginTop: 22,
          }}
          url={photosUrl}
          blank
        >
          {t('general.PhotosSection.more')}
        </ShowMoreLink>
      ) : null}

      {isOpened && (
        <Lightbox
          animationDisabled
          mainSrc={photos[photoIndex].url}
          nextSrc={photos[(photoIndex + 1) % photos.length].url}
          prevSrc={photos[(photoIndex + photos.length - 1) % photos.length].url}
          onCloseRequest={() => setIsOpened(false)}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + photos.length - 1) % photos.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % photos.length)}
        />
      )}
    </section>
  )
}
