import * as React from 'react'
import { LessonFullRecord } from 'core'
import { LessonItem, LessonItemPlaceholder } from 'components'
import './style.scss'
import { useTranslation } from 'react-i18next'
import { useLangNavigate } from 'core/hooks/useLangNavigate'

interface ComponentProps {
  loading: boolean
  resource: LessonFullRecord
}

export const LessonPagination = (props: ComponentProps) => {
  const { loading, resource } = props
  const { root } = useLangNavigate()

  const { t } = useTranslation()

  const prevLesson = t('pages.course.pagination.prev')
  const nextLesson = t('pages.course.pagination.next')

  return (
    <div className="lesson-pagination">
      {!loading ? (
        resource.prev && (
          <>
            <span>{prevLesson}</span>
            <LessonItem
              lesson={resource.prev}
              url={root.courses.lesson(resource.course.slug, resource.prev.slug)}
              pagination
            />
          </>
        )
      ) : (
        <>
          <span>{prevLesson}</span>
          <LessonItemPlaceholder />
        </>
      )}
      {!loading ? (
        resource.next && (
          <>
            <span>{nextLesson}</span>
            <LessonItem
              lesson={resource.next}
              url={root.courses.lesson(resource.course.slug, resource.next.slug)}
              pagination
            />
          </>
        )
      ) : (
        <>
          <span>{nextLesson}</span>
          <LessonItemPlaceholder />
        </>
      )}
    </div>
  )
}
