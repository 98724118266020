import { createSlice } from '@reduxjs/toolkit'
import {
  EventRecord,
  SponsorRecord,
  StaticData,
  TagRecord,
  TeamMemberRecord,
} from 'core'
import type { RootState } from '../store'

interface AppDataState {
  tags: TagRecord[]
  tags_ecosystems: TagRecord[]
  tags_specialties: TagRecord[]
  tags_stacks: TagRecord[]
  vacancies_human_count: string
  speakers_count: number
  speakers_human_count: string
  upcoming_events: EventRecord[]
  static: StaticData
  passed_events: EventRecord[]
  our_team: TeamMemberRecord[]
  sponsors: SponsorRecord[]
  info_partners: SponsorRecord[]
}

const initialState: AppDataState = (window as any).__app_data

export const appDataSlice = createSlice({
  name: 'appData',
  initialState,
  reducers: {},
})

export const selectUpcomingEvents = (state: RootState) => state.appData.upcoming_events
export const selectPassedEvents = (state: RootState) => state.appData.passed_events
export const selectOurTeam = (state: RootState) => state.appData.our_team
export const selectTags = (state: RootState) => state.appData.tags
export const ecosystemsTags = (state: RootState) => state.appData.tags_ecosystems
export const specialtiesTags = (state: RootState) => state.appData.tags_specialties
export const stacksTags = (state: RootState) => state.appData.tags_stacks
export const vacanciesHumanCount = (state: RootState) => state.appData.vacancies_human_count
export const speakersCount = (state: RootState) => state.appData.speakers_count
export const speakersHumanCount = (state: RootState) => state.appData.speakers_human_count
export const selectSponsors = (state: RootState) => state.appData.sponsors
export const selectInfoPartners = (state: RootState) => state.appData.info_partners

export const appDataReducer = appDataSlice.reducer
