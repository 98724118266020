import * as React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { UserRecord } from 'core'
import { Image } from 'components'
import './style.scss'
import { useLangNavigate } from 'core/hooks/useLangNavigate'

interface ComponentProps {
  speaker: UserRecord
  centered?: boolean
  sentryRef?: any
}

const avatarPlaceholder = require('/app/assets/images/avatar-placeholder.jpeg')

export const SpeakerItem = ({ speaker, sentryRef, centered }: ComponentProps) => {
  const { full_name, avatar, company_name } = speaker
  const { root } = useLangNavigate()
  return (
    <Link
      to={root.speakers.page(speaker)}
      ref={sentryRef}
      className={classNames('speaker-item', { 'speaker-item--centered': centered })}
    >
      <Image
        src={avatar.thumb.url ? avatar.thumb.url : avatarPlaceholder}
        className="speaker-item__image"
        placeholderClassName="speaker-item__image-placeholder"
        alt="speaker"
      />
      <h4 className="speaker-item__name">{full_name}</h4>
      <p className="speaker-item__company">{company_name}</p>
    </Link>
  )
}

SpeakerItem.defaultProps = {
  centered: false,
  sentryRef: null,
} as ComponentProps
