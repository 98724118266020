import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IFilters } from '../helpers/global-events-page.logic'
import styles from './global-events-filters.module.scss'

type Props = {
  data: IFilters[]
  onCityFilter: (cityId: number) => void
}

export const GlobalEventFilters = (props: Props) => {
  const { data, onCityFilter } = props

  const [showAll, setShowAll] = useState(false)
  const [activeFilters, setActiveFilters] = useState(new Set())
  const { t } = useTranslation()
  const toggleActive = (filterId) => {
    const newActiveFilters = new Set(activeFilters)
    if (newActiveFilters.has(filterId)) {
      newActiveFilters.delete(filterId)
    } else {
      newActiveFilters.add(filterId)
    }
    setActiveFilters(newActiveFilters)
  }

  const handleShowMoreLess = () => {
    setShowAll(!showAll)
  }

  const handleActive = (id: number) => {
    toggleActive(id)
    onCityFilter(id)
  }
  return (
    <div className={styles.filters}>
      {data.slice(0, showAll ? data.length : 7).map((filter, index) => (
        <button
          key={index}
          className={`chip-item tag-filter-component__tag ${
            activeFilters.has(filter.id) ? 'chips__item--active' : ''
          }`}
          type="button"
          onClick={() => handleActive(filter.id)}
        >
          {filter.name}
        </button>
      ))}
      {data.length > 7 && (
        <div className={styles.showMore} onClick={handleShowMoreLess}>
          {showAll
            ? t('pages.events.global.filter.show.less')
            : t('pages.events.global.filter.show.more')}
        </div>
      )}
    </div>
  )
}
