import * as React from 'react'
import classNames from 'classnames'
import { Image } from 'components'
import './style.scss'

interface ComponentProps {
  imageUrl: string | null
  companyName: string
  className?: string | null
}

export const JobLogo = (props: ComponentProps) => {
  const { imageUrl, companyName, className } = props
  const letter = companyName.charAt(0).toUpperCase()

  return (
    imageUrl
      ? (
        <Image
          src={imageUrl}
          className={classNames('job-logo', className)}
          alt={companyName}
          placeholderClassName="job-logo__placeholder"
        />
      )
      : (
        <div className={classNames('job-logo job-logo--no-image', className)}>
          <span>{letter}</span>
        </div>
      )
  )
}

JobLogo.defaultProps = {
  className: null,
}
