import * as React from 'react'
import { Link } from 'react-router-dom'
import { Image } from 'components'
import { UserRecord } from 'core'
import classNames from 'classnames'
import './style.scss'
import { useLangNavigate } from 'core/hooks/useLangNavigate'

export interface ComponentProps {
  author: Partial<UserRecord>
  type: string
}

export const ContentAuthor = (props: ComponentProps) => {
  const { author, type } = props
  const { root } = useLangNavigate()

  return (
    <Link
      to={root.speakers.page(author)}
      className={classNames('content-author', `content-author--${type}`)}
    >
      <Image
        src={author.avatar.thumb.url}
        alt="content author"
        className="content-author__avatar"
        placeholderClassName="content-authors__avatar-placeholder"
      />
      <div className="content-author__text-container">
        <h3 className="content-author__name">{author.full_name}</h3>
        <h4 className="content-author__position">
          {author.work_place} {author.company_name}
        </h4>
      </div>
    </Link>
  )
}
