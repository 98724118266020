import * as React from 'react'
import { JobRecord } from 'core'
import { JobItem, ShowMoreLink } from 'components'
import './style..scss'
import { useTranslation } from 'react-i18next'

interface ComponentProps {
  title: string
  jobs: JobRecord[]
  showMoreURL?: string
}

export const JobsSection = (props: ComponentProps) => {
  const { title, jobs, showMoreURL } = props
  const { t } = useTranslation()
  const jobsRenderCount = 6

  if (jobs.length === 0) {
    return null
  }

  return (
    <div className="job-item-jobs">
      <h3>{title}</h3>

      <div
        className={
          jobs.length < jobsRenderCount
            ? 'collection-centered__jobs-container'
            : 'collection__jobs-container'
        }
      >
        {jobs.map((job) => (
          <JobItem job={job} key={job.id} />
        ))}
      </div>

      {showMoreURL ? (
        <ShowMoreLink
          url={showMoreURL}
          style={{
            margin: '0 auto',
          }}
        >
          {t('pages.jobList.more')}
        </ShowMoreLink>
      ) : null}
    </div>
  )
}

JobsSection.defaultProps = {
  showMoreURL: null,
}
