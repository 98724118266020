import * as React from 'react'
import { EventVideo } from 'core'
import { useEventsInRow } from 'core/utils'
import { VideoItem } from 'components'
import './style.scss'

interface ComponentProps {
  title: string
  videos: EventVideo[]
}

export const VideosSection = (props: ComponentProps) => {
  const eventsInRow = useEventsInRow()
  const { title, videos } = props

  if (videos.length === 0) {
    return null
  }

  return (
    <div className="event-item-videos">
      <h3 className="event-item-page__heading">
        {title}
      </h3>

      <div className={videos.length < eventsInRow ? 'collection-centered__event-container' : 'collection__event-container'}>
        { videos.map((video) => (
          <VideoItem
            centered={videos.length < eventsInRow}
            key={video.id}
            video={video.video_id}
          />
        )) }
      </div>
    </div>
  )
}
