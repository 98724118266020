import * as React from 'react'

export const useHeadings = (article) => {
  const [nestedHeadings, setNestedHeadings] = React.useState([])

  React.useEffect(() => {
    const headingElements = Array.from(
      document.querySelectorAll('.article-page__content > h2'),
    )

    const newNestedHeadings = getNestedHeadings(headingElements)
    setNestedHeadings(newNestedHeadings)
  }, [article])

  return { nestedHeadings }
}

const getNestedHeadings = (headingElements) => {
  const nestedHeadings = []

  headingElements.forEach((heading) => {
    const { innerText: title, id } = heading

    nestedHeadings.push({ id, title, items: [] })
  })

  return nestedHeadings
}
