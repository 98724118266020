export const speakersApiUrl = '/api/speakers'
export const speakerApiUrl = (slug: string): string => `/api/speakers/${slug}`
export const eventsApiUrl = '/api/events'
export const eventApiUrl = (slug: string): string => `/api/events/${slug}`
export const articlesApiUrl = '/api/posts'
export const articleApiUrl = (slug: string): string => `/api/posts/${slug}`
export const globalEventsApiUrl = '/api/global_events'
export const tagApiUrl = (slug: string): string => `/api/tags/${slug}`
export const ecosystemsApiUrl = '/api/ecosystems'
export const specialtiesApiUrl = '/api/specialties'
export const stacksApiUrl = '/api/stacks'
export const jobsApiUrl = '/api/vacancies'
export const jobApiUrl = (slug: string): string => `/api/vacancies/${slug}`
export const countriesApiUrl = '/api/countries'
export const coursesApiUrl = '/api/courses'
export const courseApiUrl = (slug: string): string => `/api/courses/${slug}`
export const lessonApiUrl = (slug: string): string => `/api/lessons/${slug}`
