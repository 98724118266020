import * as React from 'react'
import { ApiCollectionState, DataService } from 'core/services'
import { SearchState } from 'core'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { useLang } from 'core/hooks/useLang'

export const hasNextPage = ({
  loading,
  records,
  meta: { totalCount },
}: ApiCollectionState<any>): boolean => !loading && records.length < totalCount

export const fetchSearchStateFromParams = (defaultState: SearchState, history): SearchState => {
  const searchParams = new URLSearchParams(history.location.search)
  return ['search', 'tag', 'tags', 'state', 'location', 'employment'].reduce<SearchState>(
    (accum, name) => {
      const value = searchParams.get(name)
      if (value) {
        // eslint-disable-next-line no-param-reassign
        accum[name] = value
      }

      return accum
    },
    defaultState,
  )
}

interface ApiCollectionProps<T> {
  state: ApiCollectionState<T>
  onFilterChange: (filters: SearchState) => void
  onNextPage: () => void
}

export const useApiCollection = <T>(
  apiUrl: string,
  defaultFilters: SearchState = {},
  perPage: number = null,
): ApiCollectionProps<T> => {
  const history = useHistory()
  const { i18n } = useTranslation()
  const { isRussianLang, currentLanguage } = useLang()

  const [filters] = React.useState<SearchState>(fetchSearchStateFromParams(defaultFilters, history))
  const urlWithLocale = `${apiUrl}?locale=${currentLanguage}&`

  const url = isRussianLang ? apiUrl : urlWithLocale

  const [dataService, setDataService] = React.useState(
    new DataService<T>(url, filters, perPage, history),
  )

  const [apiCollectionState, setApiCollectionState] = React.useState<ApiCollectionState<T>>(
    dataService.state$.getValue(),
  )

  React.useEffect(() => {
    dataService.state$.subscribe((state) => {
      // TODO: add distinctUntilChanged for better optimisation here
      setApiCollectionState(state)
    })

    dataService.init()

    return () => {
      dataService.onDestroy()
    }
  }, [dataService])

  useEffect(() => {
    setDataService(new DataService<T>(url, filters, perPage, history))
  }, [i18n.language])

  return {
    state: apiCollectionState,
    onNextPage: dataService.loadNextPage,
    onFilterChange: dataService.setFilters,
  }
}
