import * as React from 'react'
import {
  ErrorMessage, useField,
} from 'formik'
import classNames from 'classnames'
import { Icon } from 'components/icon/Icon'
import { FileControlProps } from '../interfaces'

export const File: React.FC<FileControlProps> = ({
  name,
  labelName,
  labelText,
  wrapperClassName,
  fieldClassName,
  required,
  hideErrorMessage,
  fileName,
  setFieldValue,
} :FileControlProps) => {
  const [field, meta] = useField(name)
  const isError = meta.error && meta.touched
  const isValid = !meta.error && meta.touched
  return (
    <label className={classNames('form-control form-control__file', wrapperClassName, { required })}>
      {labelName && <h2>{labelName}</h2>}
      <div className="form-control__file-upload">
        {labelText && (<span>{labelText}</span>)}
        <Icon icon="attached" className="form-control__file-attached-icon" />
      </div>
      <input
        id={name}
        name={name}
        className={classNames('form-control__file-field',
          fieldClassName,
          { 'no-valid': isError },
          { valid: isValid })}
        type="file"
        onChange={(event) => {
          setFieldValue(name, event.currentTarget.files[0] ? event.currentTarget.files[0] : 'Выбирите файл')
        }}
      />
      <div className="form-control__file-preview">
        {fileName}
      </div>
      {!hideErrorMessage && (
      <ErrorMessage
        name={name}
        className="form-control__error"
        component="div"
      />
      )}
    </label>
  )
}
