import * as React from 'react'
import { Image } from 'components'

interface ComponentProps {
  video?: string
  image_url?: string
  title?: string
}

export const ArticleCover = ({ video, image_url, title }: ComponentProps) => {
  if (video) {
    const videoUrl = `https://www.youtube.com/embed/${video}`
    return (
      <div className="article-page__thumb">
        <iframe
          src={videoUrl}
          title={title}
          width="690"
          height="388"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    )
  }

  if (image_url) {
    return (
      <Image
        src={image_url}
        alt={title}
        className="article-page__thumb"
      />
    )
  }
  return null
}

ArticleCover.defaultProps = {
  video: null,
  image_url: null,
  title: null,
} as ComponentProps
