import * as React from 'react'
import { Content, Icon, Button } from 'components'
import { NavLink } from 'react-router-dom'
import { IconName, StaticData, useAppSelector } from 'core'
import './style.scss'
import { ReactSVG } from 'react-svg'
import { useTranslation } from 'react-i18next'
import { useLangNavigate } from 'core/hooks/useLangNavigate'

const logo = require('/app/assets/images/logo.svg')

const buildContacts = ({ contactEmail }: StaticData) => [
  {
    uri: `mailto:${contactEmail}`,
    label: contactEmail,
    icon: 'email',
  },
]

export const Footer = () => {
  const staticData = useAppSelector((state) => state.appData.static)
  const contacts = buildContacts(staticData)
  const { t } = useTranslation()
  const { root } = useLangNavigate()

  const SiteMap = [
    {
      uri: root.main,
      label: 'general.menu.home',
    },
    {
      uri: root.events.main,
      label: 'general.menu.events',
    },
    {
      uri: root.courses.main,
      label: 'general.menu.courses',
    },
    {
      uri: root.speakers.main,
      label: 'general.menu.speakers',
    },
    {
      uri: root.blog.main,
      label: 'general.menu.articles',
    },
    {
      uri: root.about,
      label: 'general.menu.about',
    },
    {
      uri: root.events.global,
      label: 'general.menu.calendar',
    },
    {
      uri: root.jobs.main,
      label: 'general.menu.vacancies',
    },
  ]

  return (
    <Content
      element="footer"
      mainWrapperClassName="footer"
      wrapperClassName="footer__wrapper"
      className="footer__inner"
    >
      <div className="footer__site-map-container">
        <div className="footer__site-map-content">
          <h2 className="footer__block-title">{t('general.sitemap')}</h2>
          {SiteMap.map((link) => (
            <NavLink
              exact
              key={link.label}
              to={link.uri}
              className="footer__link"
              activeClassName="footer__active-link"
            >
              {t(link.label)}
            </NavLink>
          ))}
        </div>
      </div>
      <div className="footer__socials-container">
        <div className="footer__socials-content">
          <h2 className="footer__block-title">{t('general.social')}</h2>
          {staticData.socialMedia.map((link) => (
            <a
              key={link.label}
              href={link.uri}
              target="_blank"
              rel="noreferrer"
              className="footer__link"
            >
              {link.label}
            </a>
          ))}
        </div>
      </div>
      <div className="footer__telegram-container">
        <div className="footer__telegram-content">
          <h2 className="footer__block-title">{t('general.telegram')}</h2>
          {staticData.telegram.map((link) => (
            <a
              key={link.label}
              href={link.uri}
              target="_blank"
              rel="noreferrer"
              className="footer__link"
            >
              {link.label}
            </a>
          ))}
        </div>
      </div>
      <div className="footer__contacts-container">
        <div className="footer__contacts-content">
          <h2 className="footer__block-title">{t('general.contacts')}</h2>
          {contacts.map((link) => (
            <a
              className="footer__contact-link-container"
              key={link.label}
              target="_blank"
              rel="noreferrer"
              href={link.uri}
            >
              <Icon icon={link.icon as IconName} className="footer__contact-link-icon" />
              <p className="footer__contact-link-label">{link.label}</p>
            </a>
          ))}
          <Button className="footer__subscribe" mode="outlined" spShowForm="201197">
            {t('general.subscribe')}
          </Button>
        </div>
      </div>
      <div className="footer__main-block">
        <ReactSVG src={logo} className="footer__logo" />
        <p className="footer__copyright">{staticData.copyright}</p>
        <p className="footer__shortDescription">{staticData.shortDescription}</p>
      </div>
    </Content>
  )
}
