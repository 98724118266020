import * as React from 'react'

type ItemType =
  'Курс' |
  'Урок'

interface ModalProps {
  itemType: ItemType
  title: string
}

export const DisabledItemModal = (props: ModalProps) => {
  const { itemType, title } = props
  return (
    <>
      <h2>
        {` ${itemType} `}
        {` «${title}» `}
        в процессе создания ✨
      </h2>
      <p>
        Вы можете нам помочь и ускорить процесс разработки курса:
        <ul>
          <li>
            <a href="https://airtable.com/shr6zOKYIZf0BJHEn" target="_blank" rel="noreferrer">рассказать лекцию</a>
            {' '}
            на эту тему
          </li>
          <li>
            <a href="https://gitcoin.co/grants/3169/cyber-academy" target="_blank" rel="noreferrer">отправить донат</a>
            {' '}
            на Gitcoin
          </li>
        </ul>
      </p>
    </>
  )
}
