import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

import translates from './translates'

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(HttpApi)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to the react-i18next components.
  // Alternative use the I18nextProvider: https://react.i18next.com/components/i18nextprovider
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    detection: {
      // order and from where user language should be detected
      order: ['localStorage', 'navigator', 'path', 'htmlTag', 'subdomain'],

      lookupLocalStorage: 'i18nextLng',
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,

      // cache user language on
      // caches: ['localStorage', 'cookie'],
      // excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

      // optional expire and domain for set cookie
      // cookieMinutes: 10,
      // cookieDomain: window.location.hostname,

      // optional htmlTag with lang attribute, the default is:
      htmlTag: document.documentElement,
    },

    fallbackLng: 'ru',
    debug: false,

    interpolation: {
      escapeValue: false,
    },
    nsSeparator: false,

    resources: {
      en: {
        translation: translates.en,
      },
      ru: {
        translation: translates.ru,
      },
    },
  })

export default i18n
