import * as React from 'react'
import { BackgroundEllipse, Content } from 'components'
import { eventApiUrl, EventFullRecord } from 'core'
import { useApiResource } from 'core/hooks/useApiResource'
import {
  ArticlesSection,
  EventsSection,
  SpeakersSection,
  SponsorsSection,
  VideosSection,
} from 'pages/shared'
import { EventDescription, PhotosSection } from './sections'
import './style.scss'
import { useTranslation } from 'react-i18next'

export const EventItemPage = () => {
  const { resource, loading } = useApiResource<EventFullRecord>(eventApiUrl)
  const { t } = useTranslation()

  if (loading) {
    return <h1>Loading...</h1>
  }

  return (
    <div className="event">
      <Content first className="event-item-page">
        <EventDescription event={resource} />

        <SpeakersSection speakers={resource.speakers} title={t('general.SpeakersSection.title')} />

        <VideosSection title={t('general.VideosSection.title')} videos={resource.event_videos} />

        <SponsorsSection
          title={t('general.SponsorsSection.supportedBy')}
          sponsors={resource.sponsors}
          sponsorClassName="event-item-page__sponsor"
        />

        <SponsorsSection
          title={t('general.SponsorsSection.infoPartners')}
          sponsors={resource.info_partners}
          sponsorClassName="event-item-page__info-partner"
        />

        {resource.photos.length > 0 ? (
          <PhotosSection photos={resource.photos} photosUrl={resource.photos_url} />
        ) : null}

        <ArticlesSection title={t('general.ArticlesSection.title')} articles={resource.posts} />

        <EventsSection events={resource.related_events} title={t('general.EventsSection.title')} />

        <BackgroundEllipse color="green-1" className="event-item-page__figure-1" />
        <BackgroundEllipse color="green-2" className="event-item-page__figure-2" />
      </Content>
    </div>
  )
}
