import * as React from 'react'
import * as ReactDOM from 'react-dom'
import objectFitImages from 'object-fit-images'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { I18nextProvider } from 'react-i18next'
import { AppRouter } from 'core/router'
import 'react-image-lightbox/style.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import '../stylesheets/highlight.scss'
import 'quill/dist/quill.core.css'
import './normalize.scss'
import './styles.scss'
import { store } from 'redux/store'
import i18n from '../core/i18n/i18n.config'

Sentry.init({
  dsn: 'https://bfa8e34ac4524ce08497a7f2fbb6ca44@o357509.ingest.sentry.io/5892129',
  integrations: [new Integrations.BrowserTracing()],
  enabled: process.env.NODE_ENV === 'production',
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>
    </Provider>
  </I18nextProvider>,
  document.getElementById('root'),
)

objectFitImages() // it should start after render DOM
