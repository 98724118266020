import * as React from 'react'
import { useEventsInRow } from 'core/utils'
import { useApiCollection } from 'core/hooks/useApiCollection'
import { eventsApiUrl, EventRecord } from 'core'
import { EventPanelItem, EventPanelItemPlaceholder, ShowMoreLink } from 'components'
import './style.scss'
import { useTranslation, Trans } from 'react-i18next'
import { useLangNavigate } from 'core/hooks/useLangNavigate'

// TODO Refactor to Events component

interface ComponentProps {
  counter: React.Dispatch<React.SetStateAction<number>>
}

export const Events = (props: ComponentProps) => {
  const eventsRenderQuantity = useEventsInRow() * 2
  const { state } = useApiCollection<EventRecord>(eventsApiUrl, {}, eventsRenderQuantity)
  const { records, loading, meta } = state

  const { t } = useTranslation()
  const { root } = useLangNavigate()
  const { counter } = props
  React.useEffect(() => {
    if (!loading) counter(meta.totalCount)
  }, [loading])

  return (
    <section className="events events--home">
      <h2>{t('pages.home.events.heading')}</h2>

      <p>
        <Trans
          t={t}
          i18nKey="pages.home.events.description"
          components={[<span />]}
          values={{ count: !loading && meta.totalCount }}
        />
      </p>
      <div className="events__items collection__event-container">
        {records.map((event, index) => (
          <EventPanelItem key={index} event={event} />
        ))}

        {loading
          ? new Array(records.length)
              .fill(null)
              .map((value, index) => <EventPanelItemPlaceholder key={index} />)
          : null}
      </div>
      <ShowMoreLink url={root.events.main}>{t('pages.home.events.button')}</ShowMoreLink>
    </section>
  )
}
