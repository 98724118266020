import { Status } from './types'

export const setError = <State>(state: State) => ({
  ...state,
  payload: null,
  status: Status.ERROR,
})

export const setLoading = <State>(state: State) => ({
  ...state,
  status: Status.LOADING,
})

export const setSuccess = <State, Payload>(state: State, payload: Payload) => ({
  ...state,
  payload: payload ?? null,
  status: Status.SUCCESS,
})
