// Keep in sync with variables.scss

export const CollectionWidths = {
  // Courses - 4 total
  fourCoursesWith: '1350px',
  threeCoursesWith: '1024px',
  twoCoursesWith: '768px',

  // EventsSection - 4 total
  threeEventsWidth: '1350px',
  twoEventsWidth: '1024px',
  oneEventWidth: '600px',

  // SpeakersSection - 8 total
  sevenSpeakersWidth: '1400px',
  sixSpeakersWidth: '1100px',
  fiveSpeakersWidth: '950px',
  fourSpeakersWidth: '750px',
  threeSpeakersWidth: '600px',
  twoSpeakersWidth: '450px',

  // Articles - 4 total
  threeArticlesWidth: '1350px',
  twoArticlesWidth: '1024px',
  oneArticleWidth: '600px',

  // Jobs - 4 total
  threeJobsWith: '1350px',
  twoJobsWith: '1024px',
  oneJobsWith: '768px',

  // Global events - 2 total
  oneGlobalEventWidth: '1024px',

  // Products - 4 total
  threeProductsWidth: '1405px',
  twoProductsWidth: '1150px',
  oneProductWidth: '705px',
}

export const ellipseBlue = require('/app/assets/images/ellipse-blue.svg')
export const ellipseGreen1 = require('/app/assets/images/ellipse-green-1.svg')
export const ellipseGreen2 = require('/app/assets/images/ellipse-green-2.svg')
export const ellipseOrange = require('/app/assets/images/ellipse-orange.svg')
export const globalBG = require('/app/assets/images/global-bg.svg')
