import * as React from 'react'

export const CalendarIcon = () => (
  <>
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xlinkHref="http://www.w3.org/1999/xlink"
    >
      <mask
        id="mask0_5150_3092"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="16"
      >
        <rect width="16" height="16" fill="url(#pattern0)" />
      </mask>
      <g mask="url(#mask0_5150_3092)">
        <rect x="-18.24" y="-13.44" width="47.04" height="42.24" fill="#43FFDD" />
      </g>
      <defs>
        <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use xlinkHref="#image0_5150_3092" transform="scale(0.02)" />
        </pattern>
        <image
          id="image0_5150_3092"
          width="50"
          height="50"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAABFElEQVRoge2aUQ6DIAyG/y27xS7kk/d/VjmHexgSjCDgyii1X0KMQAo/pTUqQD4DgBnAastk66iobd8xeYP4g/Vi37EZj903tf8knEhTfCEjAIOje1MrE+tfWkrtGzvnA2ciQgNSCfjF/nKmPLUyuf1LKbW/axcZIylme/VXYo70vQKZ/ZQrB+xzfY0HYon96Hyp93xtNEZY8wrU9bS9HKI98vj7LK6x2zliPKJCuBGKkRitsllWzN7SIxuhFVortmUhxiMqhBtihFwJ9tR7NHVbFrf2iKbfmqgQbogRoumXG5p+uaFCuCFGiKZfbpR4hPWnVDEeUSHc8GPEAHijrx89JlQ54nuSoNaJBuqyIHKEQ+HABzez1Jjdw5d1AAAAAElFTkSuQmCC"
        />
      </defs>
    </svg>
  </>
)
