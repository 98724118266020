import * as React from 'react'
import './style.scss'

interface ComponentProps {
  times: number
}

export const ContentTextPlaceholder = (props: ComponentProps) => {
  const { times } = props
  const rowsArray = []
  for (let i = 1; i <= times; i += 1) {
    rowsArray.push('')
  }

  return (
    <div className="content-text-placeholder">
      {rowsArray.map((v, i) => <div className="content-text-placeholder__item" key={i} />)}
    </div>
  )
}
