import * as React from 'react'
import { BackgroundEllipse, Content, SearchableComponent, SpeakerItem } from 'components'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { UserRecord, speakersApiUrl } from 'core'
import { useSpeakersInRow } from 'core/utils'
import { hasNextPage, useApiCollection } from 'core/hooks/useApiCollection'
import './style.scss'
import { useTranslation } from 'react-i18next'

export const SpeakersPage = () => {
  const speakersInRow = useSpeakersInRow()

  const { state, onNextPage, onFilterChange } = useApiCollection<UserRecord>(
    speakersApiUrl,
    {},
    speakersInRow * 3,
  )
  const { t } = useTranslation()
  const { records, meta, loading, filters } = state

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: hasNextPage(state),
    onLoadMore: onNextPage,
    disabled: false,
    rootMargin: '0px 0px 1% 0px',
    delayInMs: 500,
  })

  return (
    <Content first className="speakers-page">
      <SearchableComponent
        loading={loading}
        heading={t('pages.speaker.SearchableComponent.label')}
        itemType="speaker"
        collection={records}
        renderItem={(speaker) => (
          <SpeakerItem sentryRef={sentryRef} key={speaker.id} speaker={speaker} />
        )}
        responseMeta={meta}
        metaLink={{
          label: t('pages.speaker.SearchableComponent.action'),
          href: 'https://airtable.com/shrC3Pmrs7XpCPY7M',
        }}
        searchState={filters}
        searchTypes={['tag', 'text']}
        onFilterChange={onFilterChange}
        placeholderRows={3}
      />
      <BackgroundEllipse color="blue" className="speakers-page__figure-1" />
      <BackgroundEllipse color="blue" className="speakers-page__figure-2" />
      <BackgroundEllipse color="blue" className="speakers-page__figure-3" />
      <BackgroundEllipse color="green-1" className="speakers-page__figure-4" />
      <BackgroundEllipse color="green-2" className="speakers-page__figure-5" />
    </Content>
  )
}
