import { createEvent } from 'ics'
import { dateStringToArray } from 'core/utils/dateStringToArray'
import { IDate } from 'core/utils/reformateDates'

export const addToCalendar = (
  title: string,
  description: string,
  location: string,
  dates: IDate,
) => {
  const { formattedStart, formattedEnd } = dateStringToArray(dates)

  const event = {
    start: [formattedStart.year, formattedStart.month, formattedStart.day],
    end: [formattedEnd.year, formattedEnd.month, formattedEnd.day],
    title,
    description,
    location,
  }

  // Generate the .ics file content
  createEvent(event as any, (error, value) => {
    if (error) {
      console.error(error)
      return
    }

    const blob = new Blob([value], { type: 'text/calendar' })

    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = `${title}.ics`

    link.click()
  })
}
