import { IDate } from 'core/utils/reformateDates'

export const dateStringToArray = ({ start, end }: IDate) => {
  return {
    formattedStart: {
      year: Number(start.split('-')[0]),
      month: Number(start.split('-')[1]),
      day: Number(start.split('-')[2]),
    },
    formattedEnd: {
      year: Number(end.split('-')[0]),
      month: Number(end.split('-')[1]),
      day: Number(end.split('-')[2]),
    },
  }
}
