import * as React from 'react'
import { useHeadings } from 'core/hooks/useHeading'
import { useHeadersObserver } from 'core/hooks/useHeadersObserver'
import { ArticlesHeadings } from 'components/articles-headings/ArticlesHeadings'
import classNames from 'classnames'
import { PostFullRecord } from 'core'

interface ComponentProps {
  article: PostFullRecord
  mobileVisible: boolean
  toggleTableOfContents: () => void
}

const TableOfContent = ({
  article, mobileVisible, toggleTableOfContents,
} : ComponentProps) => {
  const [activeId, setActiveId] = React.useState()
  const { nestedHeadings } = useHeadings(article)
  useHeadersObserver(setActiveId, article)

  return (
    <div className={classNames('article-page__nav-content',
      { 'is-visible': mobileVisible })}
    >
      <ArticlesHeadings
        headings={nestedHeadings}
        activeId={activeId}
        toggleTableOfContents={toggleTableOfContents}
      />
    </div>
  )
}

export default TableOfContent
