import * as Yup from 'yup'
import { TFunction } from 'i18next'

export const getJobPostingFormSchema = (t: TFunction) => {
  const urlRegex =
    /^((https?):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i
  const intropiaLinkRegex = /^((https?):\/\/)?(intro\.ac|app\.intropia\.io)\/\S+$/i

  return Yup.object().shape({
    company_name: Yup.string().required(t('pages.job.posting.form.validation.companyName')),
    company_url: Yup.string()
      .matches(urlRegex, t('pages.job.posting.form.validation.companyUrl'))
      .required(t('pages.job.posting.form.validation.companyUrl.required')),
    title: Yup.string().required(t('pages.job.posting.form.validation.title')),
    country: Yup.string(),
    description_md: Yup.string()
      .min(80, t('pages.job.posting.form.validation.description.min'))
      .required(t('pages.job.posting.form.validation.description.required')),
    salary_min: Yup.number().positive().integer(),
    salary_max: Yup.number().positive().integer(),
    contact_tg: Yup.string().min(5, t('pages.job.posting.form.validation.tg.min')),
    contact_email: Yup.string()
      .email(t('pages.job.posting.form.validation.email.type'))
      .when('intropia_link', {
        is: (val) => !val || val.trim() === '',
        then: Yup.string().required(t('pages.job.posting.form.validation.email.required')),
        otherwise: Yup.string(),
      }),
    intropia_link: Yup.string()
      .matches(intropiaLinkRegex, t('pages.job.posting.form.validation.intropia.matches'))
      .notRequired(),
  })
}
