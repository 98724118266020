import * as React from 'react'
import { ArticleItem, SearchableComponent, Content, BackgroundEllipse, Button } from 'components'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { articlesApiUrl, PostRecord } from 'core'
import { useArticlesInRow } from 'core/utils'
import { hasNextPage, useApiCollection } from 'core/hooks/useApiCollection'
import './style.scss'
import { useTranslation } from 'react-i18next'
// import {DataService} from "core/services";

export const ArticlesPage = () => {
  const [load, setLoad] = React.useState(true)
  const [hasArticles, setHasArticles] = React.useState(true)

  const articlesInRow = useArticlesInRow()
  const { state, onNextPage, onFilterChange } = useApiCollection<PostRecord>(
    articlesApiUrl,
    {},
    articlesInRow * 4,
  )

  const { t } = useTranslation()
  const { records, meta, loading, filters } = state

  const addMoreArticles = () => setLoad(false)

  React.useEffect(() => {
    setHasArticles(hasNextPage(state))
  }, [loading])

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: hasNextPage(state),
    onLoadMore: onNextPage,
    disabled: load,
    rootMargin: '0px 0px 2% 0px',
    delayInMs: 500,
  })

  return (
    <div className="articles">
      <Content className="articles-page" first>
        <SearchableComponent
          loading={loading}
          heading={t('general.article.heading')}
          collection={records}
          itemType="article"
          responseMeta={meta}
          searchState={filters}
          onFilterChange={onFilterChange}
          renderItem={(article) => (
            <ArticleItem sentryRef={sentryRef} key={article.id} article={article} />
          )}
          placeholderRows={2}
        />
        {hasArticles && (
          <Button onClick={addMoreArticles} className="more-articles" mode="contained">
            {t('general.loadMore')}
          </Button>
        )}

        <BackgroundEllipse className="articles-page__ellipse-1" color="green-1" />
        <BackgroundEllipse className="articles-page__ellipse-2" color="blue" />
        <BackgroundEllipse className="articles-page__ellipse-3" color="green-2" />
      </Content>
    </div>
  )
}
