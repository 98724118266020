import { useEffect, useState } from 'react'
import { useLang } from 'core/hooks/useLang'
import { CourseRecord, EventRecord, JobRecord, PostRecord, TagRecord, UserRecord } from 'core'

interface IUseLangNavigate {
  currentLocalePrefix: string
  root: {
    main: string
    events: {
      main: string
      page: (event: EventRecord) => string
      global: string
    }
    speakers: {
      main: string
      page: ({ slug }: Partial<UserRecord>) => string
    }
    blog: {
      main: string
      page: ({ id, slug }: PostRecord) => string
    }
    notFound: string
    about: string
    jobs: {
      main: string
      create: string
      page: (job: JobRecord) => string
    }
    tags: {
      main: string
      page: ({ slug }: TagRecord) => string
    }
    courses: {
      main: string
      page: ({ slug }: Partial<CourseRecord>) => string
      lesson: (courseSlug: string, lessonSlug: string) => string
    }
  }
}

export const useLangNavigate = (): IUseLangNavigate => {
  const [currentLocalePrefix, setCurrentLocalePrefix] = useState<string>('')
  const { currentLocale } = useLang()

  useEffect(() => {
    if (currentLocale.short === 'en') {
      setCurrentLocalePrefix('')
    } else {
      setCurrentLocalePrefix(`/${currentLocale.short}`)
    }
  }, [currentLocale.short])

  return {
    currentLocalePrefix,
    root: {
      main: `${currentLocalePrefix}/`,
      events: {
        main: `${currentLocalePrefix}/events`,
        page: (event) => `${currentLocalePrefix}/events/${event.id}-${event.slug}`,
        global: `${currentLocalePrefix}/global-events`,
      },
      speakers: {
        main: `${currentLocalePrefix}/speakers`,
        page: ({ slug }) => `${currentLocalePrefix}/speakers/${slug}`,
      },
      blog: {
        main: `${currentLocalePrefix}/blog`,
        page: ({ id, slug }) => `${currentLocalePrefix}/blog/${id}-${slug}`,
      },
      notFound: '/page-not-found',
      about: `${currentLocalePrefix}/about-us`,
      jobs: {
        main: `${currentLocalePrefix}/jobs`,
        create: `${currentLocalePrefix}/jobs/create`,
        page: (job) => `${currentLocalePrefix}/jobs/${job.id}`,
      },
      tags: {
        main: `${currentLocalePrefix}/tags`,
        page: ({ slug }) => `${currentLocalePrefix}/tags/${slug}`,
      },
      courses: {
        main: `${currentLocalePrefix}/learn`,
        page: ({ slug }) => `${currentLocalePrefix}/learn/${slug}`,
        lesson: (courseSlug, lessonSlug) =>
          `${currentLocalePrefix}/learn/${courseSlug}/${lessonSlug}`,
      },
    },
  }
}
