import * as React from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { EventRecord, humanEventInfo } from 'core'
import { Image } from 'components'
import './style.scss'
import { useLangNavigate } from 'core/hooks/useLangNavigate'

interface ComponentProps {
  event: EventRecord
  centered?: boolean
  sentryRef?: any
}

export const EventPanelItem = (props: ComponentProps) => {
  const { event, centered, sentryRef } = props
  const { item_image, title, human_type, human_date } = event
  const { root } = useLangNavigate()

  const eventImgAlt = `${human_type} CyberAcademy ${human_date}`

  return (
    <Link
      ref={sentryRef}
      to={root.events.page(event)}
      className={classNames('event-panel-item', { 'event-panel-item--centered': centered })}
    >
      <Image
        src={item_image.thumb.url}
        className="event-panel-item__image"
        alt={eventImgAlt}
        placeholderClassName="event-panel-item__image-placeholder"
      />

      <div className="event-panel-item__text-block">
        <div className="event-panel-item__date-author">{humanEventInfo(event)}</div>
        <h3 className="event-panel-item__title">{title}</h3>
      </div>
    </Link>
  )
}

EventPanelItem.defaultProps = {
  centered: false,
  sentryRef: null,
} as ComponentProps
