import * as React from 'react'
import classNames from 'classnames'
import { PhotoItemPlaceholder } from 'components'
import './style.scss'

interface ComponentProps {
  src: string
  onClick: () => void
  centered?: boolean
}

export const PhotoItem = (props: ComponentProps) => {
  const {
    onClick, src, centered,
  } = props

  const [loading, setLoading] = React.useState<boolean>(true)

  return (
    <>
      <div
        className={classNames('photo-item', { 'photo-item--loading': loading }, { 'photo-item--centered': centered })}
        onClick={onClick}
        role="button"
      >
        <img
          src={src}
          onLoadStart={() => setLoading(true)}
          onLoad={() => setLoading(false)}
          className="photo-item__image"
          alt="photo"
        />
        <div />
      </div>

      { loading ? (
        <PhotoItemPlaceholder centered={centered} />
      ) : null }
    </>
  )
}

PhotoItem.defaultProps = {
  centered: false,
} as ComponentProps
