import * as React from 'react'
import { Player } from '@lottiefiles/react-lottie-player'
import './style.scss'
import { useSelector } from 'react-redux'
import { speakersCount } from 'core/features'
import { useTranslation, Trans } from 'react-i18next'

const mascot = require('/app/assets/images/mascot.json')

interface ComponentProps {
  eventsCount: number
}

export const CyberAcademy = (props: ComponentProps) => {
  const { eventsCount } = props
  const speakers_count = useSelector(speakersCount)
  const { t } = useTranslation()

  const getMascotSize = () => {
    if (window.matchMedia('(min-width: 1350px)').matches) {
      return { heigth: '420px', width: '420px' }
    }
    if (window.matchMedia('(min-width: 375px)').matches) {
      return { heigth: '340px', width: '340px' }
    }
    return { heigth: '288px', width: '288px' }
  }

  return (
    <section className="CyberAcademy">
      <h1>
        <Trans t={t} i18nKey="pages.home.hero.heading" components={[<span />]} />
      </h1>
      <div className="CyberAcademy__mascot">
        <Player autoplay loop src={mascot} controls={false} style={getMascotSize()} />
      </div>
      <p className="CyberAcademy__description CyberAcademy__description--sup">
        {t('pages.home.hero.description.sup')}
      </p>
      <p className="CyberAcademy__description CyberAcademy__description--sub">
        {t('pages.home.hero.description.sub')}
      </p>
      <div className="CyberAcademy__info">
        <div className="CyberAcademy__info-item">
          <span>{`${speakers_count}+`}</span>
          <span>{t('pages.home.hero.stats.speakers.label')}</span>
        </div>
        <div className="CyberAcademy__info-item">
          <span>{`${eventsCount}+`}</span>
          <span>{t('pages.home.hero.stats.events.label')}</span>
        </div>
        <div className="CyberAcademy__info-item">
          <span>{t('pages.home.hero.stats.members.value')}</span>
          <span>{t('pages.home.hero.stats.members.label')}</span>
        </div>
        <div className="CyberAcademy__info-item">
          <span>{t('pages.home.hero.stats.materials.value')}</span>
          <span>{t('pages.home.hero.stats.materials.label')}</span>
        </div>
      </div>
    </section>
  )
}
