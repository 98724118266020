import * as React from 'react'
import { EmploymentTags, LocationTags, SearchState, useAppSelector } from 'core'
import {
  StateFilterComponent,
  TagsFilterComponent,
  TextSearchComponent,
} from 'components/searchable-component/search-block/filters'
import { ecosystemsTags, specialtiesTags, stacksTags } from 'core/features'
import './style.scss'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

const getLocationTags = (t: TFunction) => {
  const locationTags: LocationTags[] = [
    {
      name: t('general.sidebar.locationTags.all'),
      id: 'all',
    },
    {
      name: t('general.sidebar.locationTags.remote'),
      id: 'remoting',
    },
    {
      name: t('general.sidebar.locationTags.office'),
      id: 'office',
    },
  ]

  return locationTags
}
const getEmploymentTags = (t: TFunction) => {
  const employmentTags: EmploymentTags[] = [
    {
      name: t('general.sidebar.employmentTags.all'),
      id: 'all',
    },
    {
      name: t('general.sidebar.employmentTags.full'),
      id: 'full',
    },
    {
      name: t('general.sidebar.employmentTags.part'),
      id: 'part',
    },
  ]

  return employmentTags
}

interface ComponentProps {
  count: string
  filters: SearchState
  onFilterChange: (filters: SearchState) => void
}

export const SideBar = ({ count, onFilterChange, filters }: ComponentProps) => {
  const stacks = useAppSelector(stacksTags)
  const specialties = useAppSelector(specialtiesTags)
  const ecosystems = useAppSelector(ecosystemsTags)
  const { t } = useTranslation()

  return (
    <div className="side-bar jobs-page__sidebar">
      <div className="side-bar__item">
        <span>{`${t('pages.jobList.filter.found')} ${count}`}</span>
      </div>
      <div className="side-bar__item">
        <TextSearchComponent onChange={(search) => onFilterChange({ ...filters, search })} active />
      </div>
      <div className="side-bar__item">
        <h3>{t('pages.jobList.filter.location')}</h3>
        <StateFilterComponent
          stateOptions={getLocationTags(t)}
          selectedState={filters.location}
          onChange={(location) => onFilterChange({ ...filters, location })}
        />
      </div>
      <div className="side-bar__item">
        <h3>{t('pages.jobList.filter.employment')}</h3>
        <StateFilterComponent
          stateOptions={getEmploymentTags(t)}
          selectedState={filters.employment}
          onChange={(employment) => onFilterChange({ ...filters, employment })}
        />
      </div>
      <div className="side-bar__item side-bar__item--tags">
        <h3>{t('pages.jobList.filter.specialty')}</h3>
        <TagsFilterComponent
          tags={specialties}
          tagsFilters={filters.tags}
          onChange={(tags) => onFilterChange({ ...filters, tags })}
        />
      </div>
      <div className="side-bar__item side-bar__item--tags">
        <h3>{t('pages.jobList.filter.stack')}</h3>
        <TagsFilterComponent
          tags={stacks}
          tagsFilters={filters.tags}
          onChange={(tags) => onFilterChange({ ...filters, tags })}
        />
      </div>
      <div className="side-bar__item side-bar__item--tags">
        <h3>{t('pages.jobList.filter.ecosystem')}</h3>
        <TagsFilterComponent
          tags={ecosystems}
          tagsFilters={filters.tags}
          onChange={(tags) => onFilterChange({ ...filters, tags })}
        />
      </div>
    </div>
  )
}
